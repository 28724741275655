import React from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from 'components/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonEditor from 'components/ArgonEditor';
import ArgonDropzoneS3 from 'components/ArgonDropzoneS3';

function ScheduleDetails({
  formik,
  editor2Key,
  scheduleAttachments,
  onFileUploaded,
  eventInstance
}) {
  return (
    <Grid item xs={12}>
      <Table>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              Schedule Details
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4} mb={1} ml={0.5}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Add information about the event schedule
                </ArgonTypography>
              </ArgonBox>
              <ArgonEditor
                id="scheduleDetails"
                name="scheduleDetails"
                uniqueKey={editor2Key}
                value={formik.values.scheduleDetails}
                onChange={(value) =>
                  formik.setFieldValue('scheduleDetails', value)
                }
                placeholder="You can add information about the event's schedule here"
              />

              <ArgonBox lineHeight={1.4} mt={2} mb={1} ml={0.5}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Attachments to Schedule Details
                </ArgonTypography>
                {scheduleAttachments?.length > 0 && (
                  <ArgonBox mt={2}>
                    {scheduleAttachments?.map((sa) => (
                      <ArgonBox
                        key={sa._id}
                        display="flex"
                        alignItems="baseline"
                        mb={0.5}>
                        <ArgonTypography
                          variant="caption"
                          color="dark"
                          fontWeight="regular">
                          <li>
                            <a
                              href={sa.url}
                              style={{ color: 'inherit' }}
                              target="_blank">
                              {sa.fileName}
                            </a>
                          </li>
                        </ArgonTypography>
                      </ArgonBox>
                    ))}
                  </ArgonBox>
                )}

                <ArgonBox mt={2} />
                <ArgonDropzoneS3
                  id="upload_logo_schedule_attachments"
                  pathToPresignedUrl={
                    process.env.REACT_APP_S3_MEDIA_UPLOAD_PRESIGNED_URL
                  }
                  onFileAdded={(url, fileName) => {
                    onFileUploaded(url, fileName);
                  }}
                  payloadPurpose="eventinstance-schedule-attachment"
                  metaData={{
                    event: eventInstance?.event?._id,
                    eventInstance: eventInstance?._id
                  }}
                  identifier={eventInstance?.event?._id}
                  options={{
                    paramName: 'file',
                    method: 'PUT',
                    chunking: true,
                    chunkSize: 1000000,
                    parallelChunkUploads: true,
                    retryChunks: true,
                    retryChunksLimit: 3,
                    forceChunking: true,
                    parallelUploads: 3,
                    addRemoveLinks: true,
                    acceptedFiles: 'image/*,.jpg,.jpeg,.png,.gif,.pdf,.txt',
                    autoQueue: true,
                    autoProcessQueue: true
                  }}
                />
              </ArgonBox>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}

export default ScheduleDetails;
