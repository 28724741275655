import * as Realm from 'realm-web';
import { getIdToken, refreshIdToken } from './cognito/cognitoAccount';

const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;

const realm = new Realm.App(REALM_APP_ID);

export const loginToRealmWithJWT = async (jwt) => {
  // Remove current user completely
  if (realm?.currentUser) {
    try {
      await realm?.currentUser?.logOut();
      await realm?.removeUser(realm?.currentUser);
    } catch (err) {
      console.error('Failed to log out from realm:', err);
    }
  }

  console.log(
    'User currently logged in to Realm (should be empty):',
    realm.currentUser
  );

  console.log('Token to log use in with:', jwt);

  // Create credential with JWT (Cognito idToken)
  const credentials = Realm.Credentials.jwt(jwt);
  console.log('Credentials:', credentials);
  try {
    const user = await realm.logIn(credentials);
    return user;
  } catch (err) {
    console.error('Failed to log in', err.message);
  }
};

export const loginToRealmWithCognitoIdTokenFromSession = async () => {
  // // Remove current user completely
  // if (realm.currentUser) {
  //   await realm.currentUser.logOut();
  //   await realm.removeUser(realm.currentUser);
  // }

  // console.log(
  //   'User currently logged in to Realm (should be empty):',
  //   realm.currentUser
  // );

  const refreshedIdToken = await refreshIdToken();
  console.log(
    'Refreshed session for new login to realm:',
    refreshedIdToken?.idToken?.jwtToken
  );

  const loggedInUser = await loginToRealmWithJWT(
    refreshedIdToken?.idToken?.jwtToken
  );
  return loggedInUser;
};

export const logoutFromRealm = async () => {
  if (realm.currentUser) {
    await realm.currentUser.logOut();
    await realm.removeUser(realm.currentUser);
  }
};
