import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import { Collapse, Divider, Grid, Icon, Switch, Tooltip } from '@mui/material';
import ArgonTypography from 'components/ArgonTypography';
import AutoCompleteTagsCreate from 'components/AutoCompleteTagsCreate.js';
import FormField from 'layouts/pages/account/components/FormField';
import { useArgonController } from 'context';
import { useMutation } from '@apollo/client';

import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  EVENTINSTANCE_DIVISION_UPDATE,
  EVENTINSTANCE_DIVISION_CREATE
} from 'GraphQL/EventInstances/mutations';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import { createEventInstanceDivision } from 'services/restApi';
import CurrencyInput from 'react-currency-input-field';
import ArgonCurrencyInput from 'components/ArgonCurrencyInput';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import ArgonSelect from 'components/ArgonSelect';
import { FormatMoney } from 'format-money-js';
import { setNewPriceForStripeProduct } from 'services/REST_API/stripe';
import { completeNewPasswordChallenge } from 'services/cognito/cognitoAccount';
import { stripeCreateNewPrice } from 'services/REST_API/stripe';
import ArgonEditor from 'components/ArgonEditor';
import { nanoid } from 'nanoid';

// const Dialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  division: PropTypes.object
};

export default function CreateDivisionDialog({
  division,
  eventInstance,
  currency,
  onClose
}) {
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [uniqueEditorId] = React.useState(nanoid().toString());

  const [workInProgress, setWorkInProgress] = React.useState(false);

  const getDecimalSeparator = (locale) => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale || i18n.language)
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === 'decimal').value;
  };

  const fm = new FormatMoney({
    decimals: 2,
    locale: i18n.language,
    decimalPoint: getDecimalSeparator(i18n.language)
  });

  const [gqlCreateDivision] = useMutation(EVENTINSTANCE_DIVISION_CREATE, {
    onCompleted: (data) => {
      console.log('onCompleted: ', data);
      // enqueueSnackbar('Your division has been created!', {
      //   variant: 'success'
      // });
    },
    refetchQueries: [
      {
        query: EVENTINSTANCE_GET_BY_ID,
        variables: { eventInstanceId: eventInstance?._id }
      }
    ],
    awaitRefetchQueries: true
  });

  const [gqlUpdateDivision] = useMutation(EVENTINSTANCE_DIVISION_UPDATE, {
    refetchQueries: [
      {
        query: EVENTINSTANCE_GET_BY_ID,
        variables: { eventInstanceId: eventInstance._id }
      }
    ],
    awaitRefetchQueries: true
  });

  const [controller] = useArgonController();
  const { darkMode } = controller;

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    // registrationFee: Yup.number().required().moreThan(-1),
    // registrationFee: Yup.test('is-decimal', 'Invalid decimal', (value) =>
    //   (value + '').match(/^\d*\.{1}\d*$/)
    // ),
    minAge: Yup.number().required().positive().integer(),
    maxAge: Yup.number().required().positive().integer(),
    // maxParticipants: Yup.number().required().positive().integer(),
    teamSize: Yup.number().required().positive().integer(),

    forIndividuals: Yup.boolean().required('Required'),
    forTeams: Yup.boolean().required('Required'),
    forUnderage: Yup.boolean().required('Required'),
    sequence: Yup.number().required().positive().integer()
  });

  // console.log("Division details: ", division);

  const insertOrUpdateDivision = async (eventInstanceId, values) => {
    console.log('values: ', values);
    setWorkInProgress(true);

    let stripePriceId = division?.stripePriceId;

    if (eventInstance?.event?.stripeProductId) {
      console.log(
        'EventInstnace has a stripe product, no taking care of prices...'
      );
      if (
        Number(division?.registrationFee) !==
          fm.un(values?.registrationFee) * 100 ||
        !stripePriceId
      ) {
        console.log(
          'No previous price for division or price has changed. Updating price...'
        );
        // creating new price for division
        await stripeCreateNewPrice({
          organizationId: eventInstance?.organizationId,
          productId: eventInstance?.stripeProductId,
          priceInCents: fm.un(values?.registrationFee) * 100,
          currency:
            eventInstance?.currency ||
            eventInstance?.event?.currency ||
            eventInstance?.event?.organization?.currency ||
            'EUR'
        })
          .then((res) => {
            console.log('Created new price: ', res);
            stripePriceId = res?.id;
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar('Something went wrong. Please try again.', {
              variant: 'error'
            });
          });
      }
    } else {
      console.log(
        'EventInstance has no stripe product, not taking care of prices in stripe...'
      );
    }
    const divisionObject = {
      data: {
        eventInstanceId: eventInstanceId,
        division: {
          ...values,
          registrationFee: Number(fm.un(values?.registrationFee) * 100),
          stripePriceId: stripePriceId
          // name: values?.name,
          // description: values?.description,
          // maxParticipants: values?.maxParticipants,
          // forIndividuals: values?.forIndividuals,
          // forTeams: values?.forTeams,
          // forUnderage: values?.forKids,
          // teamSize: values?.teamSize,
          // minAge: values?.minAge,
          // maxAge: values?.maxAge,
          // onlineQualifierRegistrationFee: values?.onlineQualifierRegistrationFee,
          // eventRegistrationFee: values?.eventRegistrationFee,
        }
      }
    };

    // alert(JSON.stringify(divisionObject, null, 2));

    if (division?._id) {
      // if id exists, update division
      console.log('Updating...');
      // if a stripe price does not exist, create one

      // let stripePriceId = division?.stripePriceId;

      // if (!stripePriceId) {
      // // creating new price for division
      // const newPrice = await setNewPriceForStripeProduct({
      //   organizationId: eventInstance?.organizationId,
      //   productId: eventInstance?.stripeProductId,
      //   newPriceInCents: values?.registrationFee * 100,
      //   newCurrency:
      //     eventInstance?.currency ||
      //     eventInstance?.event?.currency ||
      //     eventInstance?.event?.organization?.currency ||
      //     'EUR',
      //   archiveOldPrice: false
      // })
      //   .then((res) => {
      //     stripePriceId = res?.id;
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     enqueueSnackbar('Something went wrong. Please try again.', {
      //       variant: 'error'
      //     });
      //   });
      // } else {
      // if stripe price already exists, check if price has changed. if yes, update price
      // if (division?.registrationFee !== values?.registrationFee * 100) {
      //   console.log('Price for division has changed. Updating price...');
      //   // creating new price for division
      //   const newPrice = await setNewPriceForStripeProduct({
      //     organizationId: eventInstance?.organizationId,
      //     productId: eventInstance?.stripeProductId,
      //     newPriceInCents: values?.registrationFee * 100,
      //     newCurrency:
      //       eventInstance?.currency ||
      //       eventInstance?.event?.currency ||
      //       eventInstance?.event?.organization?.currency ||
      //       'EUR',
      //     archiveOldPrice: false
      //   })
      //     .then((res) => {
      //       stripePriceId = res?.id;
      //     })
      //     .catch((err) => {
      //       console.error(err);
      //       enqueueSnackbar('Something went wrong. Please try again.', {
      //         variant: 'error'
      //       });
      //     });
      // }
      // }
      gqlUpdateDivision({
        variables: { ...divisionObject }
      })
        .then(() => {
          enqueueSnackbar('Your division has been updated!', {
            variant: 'success'
          });
          // Swal.fire({
          //   title: 'Good job!',
          //   text: 'Your division has been updated!',
          //   icon: 'success',
          // });
          onClose && onClose();
        })
        .catch((err) => {
          console.error(err);
          // Swal.fire({
          //   title: 'Whoops!',
          //   text: 'Something went wrong. Please try again.',
          //   icon: 'error'
          // });
          enqueueSnackbar('Something went wrong. Please try again.', {
            variant: 'error'
          });
        })
        .finally(() => {
          setWorkInProgress(false);
        });
    } else {
      console.log('Inserting');

      // creating new price for division
      // const newPrice = await setNewPriceForStripeProduct({
      //   organizationId: eventInstance?.organizationId,
      //   productId: eventInstance?.stripeProductId,
      //   newPriceInCents: values?.registrationFee * 100,
      //   newCurrency: eventInstance?.currency,
      //   archiveOldPrice: false
      // }).catch((err) => {
      //   console.error(err);
      //   enqueueSnackbar('Something went wrong. Please try again.', {
      //     variant: 'error'
      //   });
      // });

      console.log('Inserting');

      gqlCreateDivision({
        variables: { ...divisionObject }
      })
        .then(async () => {
          enqueueSnackbar('Your division has been created!', {
            variant: 'success'
          });

          onClose && onClose();
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar('Something went wrong. Please try again.', {
            variant: 'error'
          });
          // Swal.fire({
          //   title: 'Whoops!',
          //   text: 'Something went wrong. Please try again.',
          //   icon: 'error'
          // });
        })
        .finally(() => {
          setWorkInProgress(false);
        });
    }
  };

  console.log(
    'eventInstance division lenght: ',
    eventInstance?.divisions?.length
  );

  const formik = useFormik({
    initialValues: {
      // eventId: eventId,
      _id: division?._id || undefined,
      name: division?.name || '',
      description: division?.description || '',
      maxParticipants: division?.maxParticipants || undefined,
      forIndividuals: division?.forIndividuals || false,
      forTeams: division?.forTeams || false,
      forUnderage: division?.forUnderage || false,
      teamSize: division?.teamSize || 1,
      minAge: division?.minAge || 18,
      maxAge: division?.maxAge || 99,
      registrationFee:
        Number(
          division?.registrationFee || eventInstance.registrationFee || 0
        ) / 100,

      advancingAthletesNumber: division?.advancingAthletesNumber || 0,
      advancingAthletesPercentage: division?.advancingAthletesPercentage || 0,

      assignedJudges: division?.assignedJudges || [],

      sequence: division?.sequence || eventInstance?.divisions?.length + 1 || 1,
      stripePriceId: division?.stripePriceId || undefined
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));

      // TODO: handle fees like with eventInstance
      // TODO: if eventInstance has a fee, set as default for division

      await insertOrUpdateDivision(eventInstance?._id, values);
    }
  });

  const handleCancelButton = () => {
    console.log('handleCancelButton');
    onClose && onClose();
  };

  const handleSwitchChange = (event) => {
    console.log('handleSwitchChange', event.target.name, event.target.checked);
    formik.setFieldValue(event.target.name, event.target.checked);
  };

  // console.log('Work in progress: ', workInProgress);
  return (
    <div>
      <DialogTitle id="customized-dialog-title" onClose={handleCancelButton}>
        {division?._id ? 'Edit Division' : 'Create New Division'}
      </DialogTitle>
      <DialogContent dividers>
        <ArgonBox
          component="li"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          borderRadius="lg"
          pl={0}
          pr={0}
          mb={1}
          mt={2}
          // sx={({ palette: { grey, background } }) => ({
          //   backgroundColor: darkMode ? background.default : grey[100],
          // })}
        >
          <ArgonBox width="100%" display="flex" flexDirection="column">
            <Grid container spacing={0} mt={{ xs: 1 }}>
              <Grid item xs={12}>
                <ArgonBox mb={2}>
                  <FormField
                    id="name"
                    name="name"
                    size="small"
                    label="Division name"
                    placeholder=""
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                {/* <FormField
                  // id="description"
                  // name="description"
                  size="small"
                  label="Description of division, e.g. with required movement capabilities, etc."
                  multiline
                  rows={4}
                  placeholder=""
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                /> */}
                <ArgonEditor
                  uniqueKey={uniqueEditorId}
                  id={uniqueEditorId}
                  name={uniqueEditorId}
                  value={formik.values.description}
                  onChange={(value) =>
                    formik.setFieldValue('description', value)
                  }
                  placeholder="You can add a desciption for your event instance here"
                  height="100px"
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <ArgonBox mb={1} lineHeight={0}>
                <ArgonTypography variant="caption" color="text">
                  {division.forTeams
                    ? "Max # of teams"
                    : "Max # of participants"}

                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {formik.values?.maxParticipants}
                  </ArgonTypography>
                </ArgonTypography>
              </ArgonBox>
            </Grid> */}

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant="caption" fontWeight="medium">
                      For individuals:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography variant="caption" color="text">
                        {formik.values?.forIndividuals ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems="center">
                      <Switch
                        name="forIndividuals"
                        checked={formik.values?.forIndividuals}
                        onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant="caption"
                      // color="text"
                      fontWeight="medium">
                      For underage:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        // fontWeight="medium"
                      >
                        {formik.values?.forUnderage ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems="center">
                      <Switch
                        name="forUnderage"
                        checked={formik.values?.forUnderage}
                        onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant="caption" fontWeight="medium">
                      For teams:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        // fontWeight="medium"
                      >
                        {formik.values?.forTeams ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems="center">
                      <Switch
                        name="forTeams"
                        checked={formik.values?.forTeams}
                        // onChange={handleSwitchChange}
                        onChange={(event) => {
                          if (event.target.checked) {
                            formik.setFieldValue('teamSize', 2);
                          } else {
                            formik.setFieldValue('teamSize', 1);
                          }
                          formik.setFieldValue(
                            'forTeams',
                            event.target.checked
                          );
                        }}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Collapse in={formik.values?.forTeams}>
                  <ArgonBox
                    display="flex"
                    alignItems="center"
                    width={{ xs: '100%' }}
                    justifyContent="space-between"
                    // sx={({ palette: { grey, background } }) => ({
                    //   backgroundColor: darkMode
                    //     ? background.default
                    //     : grey[100],
                    // })}
                  >
                    {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                    <ArgonBox
                    // lineHeight={1.4}
                    >
                      <ArgonTypography variant="caption" fontWeight="medium">
                        Teamsize:
                      </ArgonTypography>
                    </ArgonBox>

                    <ArgonBox
                      display="flex"
                      // justifyContent="flex-end"
                      alignItems="center"
                      width={{ xs: '100%', sm: 'auto' }}
                      mt={{ xs: 1, sm: 0 }}>
                      <ArgonBox mr={1} alignItems="center">
                        <FormField
                          id="teamSize"
                          name="teamSize"
                          value={formik.values?.teamSize}
                          style={{ maxWidth: '100px' }}
                          size="small"
                          startAdornment={
                            <ArgonBox mr={1} ml={0}>
                              #
                            </ArgonBox>
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.teamSize &&
                            Boolean(formik.errors.teamSize)
                          }
                          helperText={
                            formik.touched.teamSize && formik.errors.teamSize
                          }
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </Collapse>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant="caption" fontWeight="medium">
                      {formik.values.forTeams
                        ? 'Max # of teams'
                        : 'Max # of participants'}
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox mr={1} alignItems="center">
                      <FormField
                        id="maxParticipants"
                        name="maxParticipants"
                        value={formik.values?.maxParticipants}
                        style={{ maxWidth: '100px' }}
                        size="small"
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            #
                          </ArgonBox>
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.maxParticipants &&
                          Boolean(formik.errors.maxParticipants)
                        }
                        helperText={
                          formik.touched.maxParticipants &&
                          formik.errors.maxParticipants
                        }
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant="caption" fontWeight="medium">
                      Registration fee:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox mr={1} mt={1} alignItems="center">
                      <ArgonCurrencyInput
                        id="registrationFee"
                        name="registrationFee"
                        size="small"
                        style={{
                          maxWidth: '100px'
                        }}
                        intlConfig={{
                          locale: i18n.language,
                          currency: currency || 'EUR'
                        }}
                        // prefix={currency || 'EUR' + ' '}
                        allowNegativeValue={false}
                        placeholder=""
                        defaultValue={0}
                        decimalsLimit={2}
                        // fixedDecimalLength={2}
                        value={formik.values?.registrationFee}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value, true);
                          // formik.setTouched(name, true);
                        }}
                        error={
                          formik.touched.registrationFee &&
                          Boolean(formik.errors.registrationFee)
                        }
                        // transformRawValue={(value) => (value * 100).toString()}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant="caption"
                      // color="text"
                      fontWeight="medium">
                      Minimum age of participants (years):
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox mr={1} alignItems="center">
                      <FormField
                        id="minAge"
                        name="minAge"
                        value={formik.values?.minAge}
                        style={{ maxWidth: '100px' }}
                        size="small"
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            Yrs
                          </ArgonBox>
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.minAge && Boolean(formik.errors.minAge)
                        }
                        helperText={
                          formik.touched.minAge && formik.errors.minAge
                        }
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant="caption"
                      // color="text"
                      fontWeight="medium">
                      Maximum age of competitor (years):
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox mr={1} alignItems="center">
                      <FormField
                        id="maxAge"
                        name="maxAge"
                        value={formik.values?.maxAge}
                        style={{ maxWidth: '100px' }}
                        size="small"
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            Yrs
                          </ArgonBox>
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.maxAge && Boolean(formik.errors.maxAge)
                        }
                        helperText={
                          formik.touched.maxAge && formik.errors.maxAge
                        }
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              {(eventInstance?.isVirtualEvent === false ||
                eventInstance?.type === 'onSiteEvent') && (
                <Grid item xs={12} sm={12}>
                  <ArgonBox
                    display="flex"
                    alignItems="center"
                    width={{ xs: '100%' }}
                    justifyContent="space-between">
                    {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                    <ArgonBox
                    // lineHeight={1.4}
                    >
                      <ArgonTypography
                        variant="caption"
                        // color="text"
                        fontWeight="medium">
                        Sequence of this division (used for heat planning)
                      </ArgonTypography>
                    </ArgonBox>

                    <ArgonBox
                      display="flex"
                      // justifyContent="flex-end"
                      alignItems="center"
                      width={{ xs: '100%', sm: 'auto' }}
                      mt={{ xs: 1, sm: 0 }}>
                      <ArgonBox mr={1} alignItems="center">
                        <FormField
                          id="sequence"
                          name="sequence"
                          value={formik.values?.sequence}
                          style={{ maxWidth: '100px' }}
                          size="small"
                          startAdornment={
                            <ArgonBox mr={1} ml={0}>
                              #
                            </ArgonBox>
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.sequence &&
                            Boolean(formik.errors.sequence)
                          }
                          helperText={
                            formik.touched.sequence && formik.errors.sequence
                          }
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  alignItems="center"
                  width={{ xs: '100%' }}
                  justifyContent="space-between">
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant="caption"
                      // color="text"
                      fontWeight="medium">
                      How many athletes will advance to the next round?
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}>
                    <ArgonBox mr={1} alignItems="center">
                      <FormField
                        id="advancingAthletesNumber"
                        name="advancingAthletesNumber"
                        value={formik.values?.advancingAthletesNumber}
                        style={{ maxWidth: '100px' }}
                        size="small"
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            #
                          </ArgonBox>
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.advancingAthletesNumber &&
                          Boolean(formik.errors.advancingAthletesNumber)
                        }
                        helperText={
                          formik.touched.advancingAthletesNumber &&
                          formik.errors.advancingAthletesNumber
                        }
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              {(eventInstance?.isVirtualEvent === true ||
                eventInstance?.type === 'onlineQualifier') && (
                <Grid item xs={12} sm={12}>
                  <ArgonBox
                    display="flex"
                    alignItems="center"
                    width={{ xs: '100%' }}
                    justifyContent="space-between"
                    mt={1}>
                    {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                    <ArgonBox
                    // lineHeight={1.4}
                    >
                      <ArgonTypography
                        variant="caption"
                        // color="text"
                        fontWeight="medium">
                        Assign judge to this division
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox
                      display="flex"
                      // justifyContent="flex-end"
                      alignItems="center"
                      width={{ xs: '100%', sm: 'auto' }}
                      mt={{ xs: 1, sm: 0 }}>
                      <ArgonBox mr={1} alignItems="center">
                        <ArgonSelect
                          id="assignedJudges"
                          name="assignedJudges"
                          value={formik.values?.assignedJudges}
                          style={{ maxWidth: '100px' }}
                          size="small"
                          options={{}}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
              )}
            </Grid>
          </ArgonBox>
        </ArgonBox>
      </DialogContent>
      <DialogActions>
        <ArgonBox
          width={{ xs: '100%' }}
          justifyContent="space-between"
          display="flex">
          <ArgonButton
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleCancelButton}>
            Cancel
          </ArgonButton>
          <ArgonButton
            variant="gradient"
            color="success"
            size="small"
            onClick={formik.submitForm}
            loading={workInProgress}
            style={{ width: '170px' }}>
            {division?._id ? 'Update Division' : 'Create Division'}
          </ArgonButton>
        </ArgonBox>
        {/* <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus color="success" onClick={handleClose}>
          Save changes
        </Button> */}
      </DialogActions>
    </div>
  );
}
