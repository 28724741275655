import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';
import { Add as AddIcon } from '@mui/icons-material';
import { useArgonController } from 'context';
import { Alert } from '@mui/material';

function CreateEventInstances({ onCreateNewEventInstance }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  return (
    <ArgonBox mt={3}>
      <Card>
        <ArgonBox p={3}>
          <ArgonTypography variant="h5">Create Event Instances</ArgonTypography>
        </ArgonBox>
        <ArgonBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <ArgonBox mt={2}>
                <Alert severity="info">
                  All events need to have at least one event instance.
                </Alert>
              </ArgonBox>

              <ArgonBox mt={2} />
              <ArgonButton
                variant="contained"
                size="large"
                fullWidth
                sx={({ palette: { grey, background } }) => ({
                  backgroundColor: darkMode ? background.default : grey[100]
                })}
                onClick={() =>
                  onCreateNewEventInstance &&
                  onCreateNewEventInstance({ type: 'onlineQualifier' })
                }>
                <ArgonBox width="100%" display="flex" flexDirection="column">
                  <ArgonBox
                    display="flex"
                    justifyContent="left"
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    mb={3}
                    mt={3}>
                    <ArgonBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}>
                      <AddIcon fontSize="lg" color="info" />
                    </ArgonBox>
                    <ArgonBox
                      display="flex"
                      alignItems="start"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}>
                      <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        align="left"
                        ml={2}>
                        <div>Add Virtual Event Instance</div>
                        <ArgonTypography
                          variant="button"
                          fontWeight="light"
                          align="left">
                          Participants register for events without a distinct
                          location (e.g. an online qualifier)
                        </ArgonTypography>
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </ArgonButton>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ArgonButton
                variant="contained"
                size="large"
                fullWidth
                sx={({ palette: { grey, background } }) => ({
                  backgroundColor: darkMode ? background.default : grey[100]
                })}
                onClick={() =>
                  onCreateNewEventInstance &&
                  onCreateNewEventInstance({ type: 'onSiteEvent' })
                }>
                <ArgonBox width="100%" display="flex" flexDirection="column">
                  <ArgonBox
                    display="flex"
                    justifyContent="left"
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    mb={3}
                    mt={3}>
                    <ArgonBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}>
                      <AddIcon fontSize="lg" color="info" />
                    </ArgonBox>
                    <ArgonBox
                      display="flex"
                      alignItems="start"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}>
                      <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        align="left"
                        ml={2}>
                        <div>Add On-Site Event Instance</div>
                        <ArgonTypography
                          variant="button"
                          fontWeight="light"
                          align="left">
                          Participants register for events taking place at a
                          distinct location (e.g. a finale)
                        </ArgonTypography>
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </ArgonButton>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ArgonButton
                variant="contained"
                size="large"
                fullWidth
                sx={({ palette: { grey, background } }) => ({
                  backgroundColor: darkMode ? background.default : grey[100]
                })}
                onClick={() =>
                  onCreateNewEventInstance &&
                  onCreateNewEventInstance({ type: 'leaderboard' })
                }>
                <ArgonBox width="100%" display="flex" flexDirection="column">
                  <ArgonBox
                    display="flex"
                    justifyContent="left"
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    mb={3}
                    mt={3}>
                    <ArgonBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}>
                      <AddIcon fontSize="lg" color="info" />
                    </ArgonBox>
                    <ArgonBox
                      display="flex"
                      alignItems="start"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}>
                      <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        align="left"
                        ml={2}>
                        <div>Add Leaderboard Only</div>
                        <ArgonTypography
                          variant="button"
                          fontWeight="light"
                          align="left">
                          Offer only a leaderboard, participants cannot register
                          for this event instance
                        </ArgonTypography>
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </ArgonButton>
            </Grid>
          </Grid>
        </ArgonBox>
      </Card>
    </ArgonBox>
  );
}

export default CreateEventInstances;
