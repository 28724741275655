/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const selectData = {
  organizationType: [
    { value: 'business', label: 'Legal entity' },
    { value: 'private', label: 'Private person' }
  ],

  eventType: [
    { value: 'competition', label: 'Competition' },
    { value: 'meetup', label: 'Meetup' },
    { value: 'workshop', label: 'Workshop' }
  ],

  sportsType: [
    { value: 'crossfit', label: 'CrossFit' },
    // { value: 'hyrox', label: 'HYROX' },
    { value: 'powerlifting', label: 'Powerlifting' },
    { value: 'weightlifting', label: 'Weightlifting' },
    { value: 'leaderboard', label: 'Leaderboard only' }
  ],
  scoreSubmissionTypes: [
    {
      value: 'time-lower',
      label: 'For time in minutes (lower is better)',
      short: 'min'
    },
    {
      value: 'time-higher',
      label: 'For time in minutes (higher is better)',
      short: 'min'
    },
    {
      value: 'reps-higher',
      label: 'For repetitions (higher is better)',
      short: 'reps'
    },
    {
      value: 'reps-lower',
      label: 'For repetitions (lower is better)',
      short: 'reps'
    },
    {
      value: 'rounds-higher',
      label: 'For rounds (higher is better)',
      short: 'rounds'
    },
    {
      value: 'rounds-lower',
      label: 'For rounds (lower is better)',
      short: 'rounds'
    },
    {
      value: 'calories',
      label: 'For calories (higher is better)',
      short: 'cal'
    },
    {
      value: 'distance-mi',
      label: 'For distance in miles (higher is better)',
      short: 'miles'
    },
    {
      value: 'distance-km',
      label: 'For distance in kilometer (higher is better)',
      short: 'km'
    },
    {
      value: 'distance-mtrs',
      label: 'For distance in meters (more is better)',
      short: 'km'
    },
    {
      value: 'distance-yrds',
      label: 'For distance in yards (more is better)',
      short: 'km'
    },

    {
      value: 'weight-kg',
      label: 'For weight in Kg (higher is better)',
      short: 'kg'
    },
    {
      value: 'weight-lbs',
      label: 'For weight in lbs (higher is better)',
      short: 'lbs'
    }
  ],

  timeFormats: [
    {
      value: 'mm:ss.MMM',
      label: 'mm:ss.MMM',
      validationScheme: /^(([0]?[0-5][0-9]|[0-9]):([0-5][0-9])\.([0-9]{3}))$/
    },
    {
      value: 'mm:ss',
      label: 'mm:ss',
      validationScheme: /^(([0]?[0-5][0-9]|[0-9]):([0-5][0-9]))$/
    },
    {
      value: 'hh:mm:ss.MMM',
      label: 'hh:mm:ss.MMM',
      validationScheme:
        /^(([0]?[0-9]|[0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])\.([0-9]{3}))$/
    },
    {
      value: 'hh:mm:ss',
      label: 'hh:mm:ss',
      validation:
        /^(([0]?[0-9]|[0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9]))$/
    },
    { value: 'ss', label: 'ss', validationScheme: /^([0-5][0-9]|[0-9])$/ }
  ],

  scoringWeights: [
    { value: '1', label: '1x (points will not be adjusted)' },
    { value: '2', label: '2x (points will be doubled)' },
    { value: '3', label: '3x (points are multiplied by 3)' },
    { value: '4', label: '4x (points are multiplied by 4)' },
    { value: '5', label: '5x (points are multiplied by 5)' }
  ],

  gender: [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
  ],

  eventInstanceTypes: [
    { value: 'onlineQualifier', label: 'Online qualifier' },
    { value: 'onSiteQualifier', label: 'On site qualifier' },
    // { value: "additionalStage", label: "Additional stage before finale" },
    // { value: "day", label: "Event day" },
    { value: 'onSiteEvent', label: 'On site event' },
    { value: 'finale', label: 'Event finale' },
    { value: 'leaderboard', label: 'Leadberboard' }
  ],

  registrationOptions: [
    { value: 'open', label: 'Open - everybody can register' },
    { value: 'private', label: 'Private - access by invitation only' }
  ],

  organizationPaymentSelection: [
    {
      value: 'noFees',
      label: 'At the moment I do not plan to charge for events'
    },
    {
      value: 'existingStripeAccount',
      label: 'I already have a Stripe account that I will connect'
    },
    {
      value: 'createNewStripeAccount',
      label: 'I want to create a new Stripe account'
    }
  ],

  shippingOptions: [
    {
      value: 'free',
      label: 'Free shipping',
      shipping: true,
      description1: 'This product will be shipped for free',
      description2: 'These products will be shipped for free',
      descriptionAll: 'All products will be shipped for free'
    },
    {
      value: 'standardDelivery',
      label: 'Standard delivery',
      shipping: true,
      description1: 'This product will be shipped with standard delivery',
      description2: 'These products will be shipped with standard delivery',
      descriptionAll: 'All products will be shipped with standard delivery'
    },
    {
      value: 'expressDelivery',
      label: 'Express delivery',
      shipping: true,
      description1: 'This product will be shipped with express delivery',
      description2: 'These products will be shipped with express delivery',
      descriptionAll: 'All products will be shipped with express delivery'
    },
    {
      value: 'localPickup',
      label: 'Local pickup',
      shipping: false,
      description1: 'This product will be available for pickup from the vendor',
      description2:
        'These products will be available for pickup from the vendor',
      descriptionAll:
        'All products will be available for pickup from the vendor'
    },
    {
      value: 'eventPickup',
      label: 'Event pickup',
      shipping: false,
      description1:
        'This product will be available for pickup at the event location',
      description2:
        'These products will be available for pickup at the event location',
      descriptionAll:
        'All products will be available for pickup at the event location'
    },
    {
      value: 'internationalDelivery',
      label: 'International delivery',
      shipping: true,
      description1: 'This product will be shipped internationally',
      description2: 'These products will be shipped internationally',
      descriptionAll: 'All products will be shipped internationally'
    },
    {
      value: 'download',
      label: 'Download',
      shipping: false,
      description1: 'This product will be available for download',
      description2: 'These products will be available for download',
      descriptionAll: 'All products will be available for download'
    },
    {
      value: 'noShipping',
      label: 'No shipping',
      shipping: false,
      description1: 'This product will not be shipped',
      description2: 'These products will not be shipped',
      descriptionAll: 'None of the products will be shipped'
    }
  ],

  productTypes: [
    {
      value: 'physical',
      label: 'Physical product',
      shippingOptions: [
        'free',
        'standardDelivery',
        'expressDelivery',
        'localPickup',
        'eventPickup',
        'internationalDelivery'
      ]
    },
    {
      value: 'digital',
      label: 'Digital product',
      shippingOptions: ['download']
    },
    {
      value: 'service',
      label: 'Service rendered',
      shippingOptions: ['noShipping']
    }
  ],

  judgementSelectionMethod: [
    {
      value: 'all',
      label: 'All submitted scores',
      description:
        'All submitted scores will be assigned to a judge for verification',
      topXScores: 0,
      randomScoresPercentage: 100
    },
    {
      value: 'random10%',
      label: 'Random selection of 10%',
      description:
        'A random selection of 10% of all submitted scores will be assigned to a judge for verification',
      topXScores: 0,
      randomScoresPercentage: 10
    },
    {
      value: 'random25%',
      label: 'Random selection of 25%',
      description:
        'A random selection of 25% of all submitted scores will be assigned to a judge for verification',
      topXScores: 0,
      randomScoresPercentage: 25
    },
    {
      value: 'random50%',
      label: 'Random selection of 50%',
      description:
        'A random selection of 50% of all submitted scores will be assigned to a judge for verification',
      topXScores: 0,
      randomScoresPercentage: 50
    },

    {
      value: 'top10PerDivision',
      label: 'Top 10 scores per division',
      description:
        'Per division, the top 10 results of all submitted scores will be assigned to a judge for verification',
      topXScores: 10,
      randomScoresPercentage: 0
    },
    {
      value: 'top15PerDivision',
      label: 'Top 15 scores per division',
      description:
        'Per division, the top 15 results of all submitted scores will be assigned to a judge for verification',
      topXScores: 15,
      randomScoresPercentage: 0
    },
    {
      value: 'top20PerDivision',
      label: 'Top 20 scores per division',
      description:
        'Per division, the top 20 results of all submitted scores will be assigned to a judge for verification',
      topXScores: 20,
      randomScoresPercentage: 0
    },
    {
      value: 'top10PerDivisionAndRandom10Percent',
      label: 'Top 10 scores and random 10% per division',
      description:
        'Per division, the top 10 and a random selection of 10% of all submitted scores will be assigned to a judge for verification',
      topXScores: 10,
      randomScoresPercentage: 10
    }
  ],

  registrationStates: [
    {
      value: 'active',
      label: 'Active',
      description: 'Registration is active, nothing to do.',
      color: 'success'
    },
    {
      value: 'pending',
      // label: 'Pending',
      label: 'Waiting ToS',
      description:
        'Not all participants have agreed to the Terms of Service. They will receive gentle reminders. If ToS are not accepted 30 days after registration, the participant will be removed from the team.',
      color: 'secondary'
    },
    {
      value: 'reservation',
      label: 'Reservation',
      description:
        'A participant / team reserved a spot but payment has not been initiated yet.',
      color: 'info'
    },

    {
      value: 'withdrawn',
      label: 'Withdrawn',
      description:
        'The registration has been withdrawn, either by the participant or the organizer.',
      color: 'warning'
    }
  ],

  paymentStates: [
    {
      value: 'free',
      label: 'Free',
      description: 'No registration fee requested.',
      color: 'success'
    },
    {
      value: 'paid',
      label: 'Paid',
      description: 'Registration fee has been paid.',
      color: 'success'
    },
    {
      value: 'processing',
      label: 'Processing',
      description:
        "Payment is being processed. Funds may take a while until they arrive on the organizers' balance.",
      color: 'info'
    },
    {
      value: 'open',
      label: 'Open',
      description:
        'Checkout was started, but payment has not yet been initiated by registrant. These registrations will be cancelled automatially after 15 days.',
      color: 'error'
    },
    {
      value: 'refunded',
      label: 'Refunded',
      description:
        'Payment has been refunded it total or in part. The registrant may have withdrawn from the event or the organizer may have cancelled the registration.',
      color: 'secondary'
    }
  ],

  checkInStates: [
    {
      value: 'complete',
      label: 'Complete',
      description: 'Check In complete.',
      color: 'success'
    },
    {
      value: 'incomplete',
      label: 'Not Checked In',
      description: 'Not checkd in.',
      color: 'secondary'
    },
    {
      value: null,
      label: 'Not Checked In',
      description: 'Not checkd in.',
      color: 'secondary'
    }
  ],

  scheduledMessagesMessageTypes: [
    { value: 'email', label: 'Email' }
    // { value: 'push', label: 'Push notification' },
    // { value: 'sms', label: 'SMS' }
  ],

  scheduledMessagesRecipientBases: [
    { value: 'participants', label: 'Participants' },
    { value: 'registrationOwners', label: 'Registration Owners' },
    { value: 'volunteers', label: 'Volunteers' }
    // { value: 'judges', label: 'Judges' },
    // { value: 'spectators', label: 'Spectators' }
  ],

  scheduledMessagesSendTypes: [
    { value: 'relative', label: 'Relative to event date' },
    { value: 'absolute', label: 'Absolute date' }
  ],

  scheduledMessagesRelativeSendDateEvents: [
    { value: 'afterRegistration', label: 'after registration' },
    { value: 'beforeEvent', label: 'before event' },
    { value: 'afterEvent', label: 'after event' }
  ],

  autoInvitationMethodOptions: [
    { value: 'top', label: 'Top ranks with fill up' }
  ],

  countries: [
    { value: 'Germany', label: 'Germany', code2: 'DE', code3: 'DEU' },
    { value: 'Spain', label: 'Spain', code2: 'ES', code3: 'ESP' },
    { value: 'Switzerland', label: 'Switzerland', code2: 'CH', code3: 'CHE' },
    { value: 'Netherland', label: 'Netherland', code2: 'NL', code3: 'NLD' },
    { value: 'Belgium', label: 'Belgium', code2: 'BE', code3: 'BEL' },
    { value: 'France', label: 'France', code2: 'FR', code3: 'FRA' },
    { value: 'Italy', label: 'Italy', code2: 'IT', code3: 'ITA' },
    { value: 'Austria', label: 'Austria', code2: 'AT', code3: 'AUT' },
    {
      value: 'United Kingdom',
      label: 'United Kingdom',
      code2: 'GB',
      code3: 'GBR'
    },
    { value: 'Sweden', label: 'Sweden', code2: 'SE', code3: 'SWE' },
    { value: 'Norway', label: 'Norway', code2: 'NO', code3: 'NOR' },
    { value: 'Denmark', label: 'Denmark', code2: 'DK', code3: 'DNK' },
    { value: 'Finnland', label: 'Finnland', code2: 'FI', code3: 'FIN' },
    { value: 'Ireland', label: 'Ireland', code2: 'IE', code3: 'IRL' },
    { value: 'Portugal', label: 'Portugal', code2: 'PT', code3: 'PRT' },
    { value: 'Greece', label: 'Greece', code2: 'GR', code3: 'GRC' },
    { value: 'Poland', label: 'Poland', code2: 'PL', code3: 'POL' },
    {
      value: 'Czech Republic',
      label: 'Czech Republic',
      code2: 'CZ',
      code3: 'CZE'
    },
    { value: 'Hungary', label: 'Hungary', code2: 'HU', code3: 'HUN' },
    { value: 'Romania', label: 'Romania', code2: 'RO', code3: 'ROU' },
    { value: 'Bulgaria', label: 'Bulgaria', code2: 'BG', code3: 'BGR' },
    { value: 'Slovakia', label: 'Slovakia', code2: 'SK', code3: 'SVK' },
    { value: 'Croatia', label: 'Croatia', code2: 'HR', code3: 'HRV' },
    { value: 'Slovenia', label: 'Slovenia', code2: 'SI', code3: 'SVN' },
    { value: 'Lithuania', label: 'Lithuania', code2: 'LT', code3: 'LTU' },
    { value: 'Latvia', label: 'Latvia', code2: 'LV', code3: 'LVA' },
    { value: 'Estonia', label: 'Estonia', code2: 'EE', code3: 'EST' },
    { value: 'Belarus', label: 'Belarus', code2: 'BY', code3: 'BLR' },
    { value: 'Ukraine', label: 'Ukraine', code2: 'UA', code3: 'UKR' },
    { value: 'Russia', label: 'Russia', code2: 'RU', code3: 'RUS' },
    { value: 'Moldova', label: 'Moldova', code2: 'MD', code3: 'MDA' }
  ],

  birthDate: [
    { value: 'january', label: 'January' },
    { value: 'february', label: 'February' },
    { value: 'march', label: 'March' },
    { value: 'april', label: 'April' },
    { value: 'may', label: 'May' },
    { value: 'june', label: 'June' },
    { value: 'july', label: 'July' },
    { value: 'august', label: 'August' },
    { value: 'september', label: 'September' },
    { value: 'october', label: 'October' },
    { value: 'november', label: 'November' },
    { value: 'december', label: 'December' }
  ],
  days: [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 }
  ],
  years: [
    { value: 1900, label: 1900 },
    { value: 1901, label: 1901 },
    { value: 1902, label: 1902 },
    { value: 1903, label: 1903 },
    { value: 1904, label: 1904 },
    { value: 1905, label: 1905 },
    { value: 1906, label: 1906 },
    { value: 1907, label: 1907 },
    { value: 1908, label: 1908 },
    { value: 1909, label: 1909 },
    { value: 1910, label: 1910 },
    { value: 1911, label: 1911 },
    { value: 1912, label: 1912 },
    { value: 1913, label: 1913 },
    { value: 1914, label: 1914 },
    { value: 1915, label: 1915 },
    { value: 1915, label: 1915 },
    { value: 1915, label: 1915 },
    { value: 1916, label: 1916 },
    { value: 1917, label: 1917 },
    { value: 1918, label: 1918 },
    { value: 1919, label: 1919 },
    { value: 1920, label: 1920 },
    { value: 1921, label: 1921 },
    { value: 1922, label: 1922 },
    { value: 1923, label: 1923 },
    { value: 1924, label: 1924 },
    { value: 1925, label: 1925 },
    { value: 1926, label: 1926 },
    { value: 1927, label: 1927 },
    { value: 1928, label: 1928 },
    { value: 1929, label: 1929 },
    { value: 1930, label: 1930 },
    { value: 1931, label: 1931 },
    { value: 1932, label: 1932 },
    { value: 1933, label: 1933 },
    { value: 1934, label: 1934 },
    { value: 1935, label: 1935 },
    { value: 1936, label: 1936 },
    { value: 1937, label: 1937 },
    { value: 1938, label: 1938 },
    { value: 1939, label: 1939 },
    { value: 1940, label: 1940 },
    { value: 1941, label: 1941 },
    { value: 1942, label: 1942 },
    { value: 1943, label: 1943 },
    { value: 1944, label: 1944 },
    { value: 1945, label: 1945 },
    { value: 1946, label: 1946 },
    { value: 1947, label: 1947 },
    { value: 1948, label: 1948 },
    { value: 1949, label: 1949 },
    { value: 1950, label: 1950 },
    { value: 1951, label: 1951 },
    { value: 1952, label: 1952 },
    { value: 1953, label: 1953 },
    { value: 1954, label: 1954 },
    { value: 1955, label: 1955 },
    { value: 1956, label: 1956 },
    { value: 1957, label: 1957 },
    { value: 1958, label: 1958 },
    { value: 1959, label: 1959 },
    { value: 1960, label: 1960 },
    { value: 1961, label: 1961 },
    { value: 1962, label: 1962 },
    { value: 1963, label: 1963 },
    { value: 1964, label: 1964 },
    { value: 1965, label: 1965 },
    { value: 1966, label: 1966 },
    { value: 1967, label: 1967 },
    { value: 1968, label: 1968 },
    { value: 1969, label: 1969 },
    { value: 1970, label: 1970 },
    { value: 1971, label: 1971 },
    { value: 1972, label: 1972 },
    { value: 1973, label: 1973 },
    { value: 1974, label: 1974 },
    { value: 1975, label: 1975 },
    { value: 1976, label: 1976 },
    { value: 1977, label: 1977 },
    { value: 1978, label: 1978 },
    { value: 1979, label: 1979 },
    { value: 1980, label: 1980 },
    { value: 1981, label: 1981 },
    { value: 1982, label: 1982 },
    { value: 1983, label: 1983 },
    { value: 1984, label: 1984 },
    { value: 1985, label: 1985 },
    { value: 1986, label: 1986 },
    { value: 1987, label: 1987 },
    { value: 1988, label: 1988 },
    { value: 1989, label: 1989 },
    { value: 1990, label: 1990 },
    { value: 1991, label: 1991 },
    { value: 1992, label: 1992 },
    { value: 1993, label: 1993 },
    { value: 1994, label: 1994 },
    { value: 1995, label: 1995 },
    { value: 1996, label: 1996 },
    { value: 1997, label: 1997 },
    { value: 1998, label: 1998 },
    { value: 1999, label: 1999 },
    { value: 2000, label: 2000 },
    { value: 2001, label: 2001 },
    { value: 2002, label: 2002 },
    { value: 2003, label: 2003 },
    { value: 2004, label: 2004 },
    { value: 2005, label: 2005 },
    { value: 2006, label: 2006 },
    { value: 2007, label: 2007 },
    { value: 2008, label: 2008 },
    { value: 2009, label: 2009 },
    { value: 2010, label: 2010 },
    { value: 2011, label: 2011 },
    { value: 2012, label: 2012 },
    { value: 2013, label: 2013 },
    { value: 2014, label: 2014 },
    { value: 2015, label: 2015 },
    { value: 2016, label: 2016 },
    { value: 2017, label: 2017 },
    { value: 2018, label: 2018 },
    { value: 2019, label: 2019 },
    { value: 2020, label: 2020 },
    { value: 2021, label: 2021 }
  ]
};

export default selectData;
