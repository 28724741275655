/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, Suspense, lazy } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// // Argon Dashboard 2 PRO MUI example components
// import Sidenav from 'Routes/Organizer/Dashboard/Sidenav';
// import Configurator from 'examples/Configurator';

// Argon Dashboard 2 PRO MUI themes
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';
import themeDark from 'assets/theme-dark';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';

// My own CSS additions
import 'assets/css/custom.module.css';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Argon Dashboard 2 PRO MUI routes
import routes from 'routes'; //! do no remove, it is needed for the sidenav to work

import { SnackbarProvider } from 'notistack';

// Argon Dashboard 2 PRO MUI contexts
import {
  useArgonController,
  setMiniSidenav,
  setOpenConfigurator
} from 'context';

// Images
// import brand from 'assets/images/logos/logo_with_text_two_rows_white.png';
// import brandDark from 'assets/images/logos/logo_with_text_two_rows_blue.png';

// Icon Fonts
import 'assets/css/nucleo-icons.css';
import 'assets/css/nucleo-svg.css';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink
} from '@apollo/client';

import UserPool from 'services/cognito/userPool';
import * as Realm from 'realm-web';
// import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { getSession } from 'services/cognito/cognitoAccount';
// import { PuffLoader } from 'react-spinners';
// import { Box } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import Loader from 'Loader';
// import CheckoutPage from 'Routes/LandingPage/ShoppingCart/checkout';
import ScrollToTop from 'services/ScrollToTop';

import React from 'react';
// import { ChatWidget } from '@papercups-io/chat-widget';

//Indivdual routes

// Linktree
const LinkTreeDemo = lazy(() => import('Routes/LandingPage/Linktree/Demo'));

const LoginPage = lazy(() => import('Routes/Login'));
const LogoutPage = lazy(() => import('Routes/Logout'));
const SignupPage = lazy(() => import('Routes/SignUp'));
const ForgotPasswordPage = lazy(() => import('Routes/ForgotPassword'));

const Imprint = lazy(() => import('Routes/Legal/Imprint'));
const Privacy = lazy(() => import('Routes/Legal/Privacy'));
const TermsOfService = lazy(() => import('Routes/Legal/TermsOfService'));

const CheckoutPage = lazy(() =>
  import('Routes/LandingPage/ShoppingCart/checkout')
);
const ShippingAndBillingAddressCollection = lazy(() =>
  import('Routes/LandingPage/ShoppingCart/BillingAndShippingDetails')
);

const AthleteProfile = lazy(() => import('Routes/Athlete/Profile'));
const AthleteRegistrations = lazy(() => import('Routes/Athlete/Registrations'));

const AthleteSpectatorTickets = lazy(() =>
  import('Routes/Athlete/SpectatorTickets')
);
const AthleteSpectatorTicketDetails = lazy(() =>
  import('Routes/Athlete/SpectatorTickets/Details')
);

const AthleteVolunteerRegistrations = lazy(() =>
  import('Routes/Athlete/VolunteerRegistrations')
);

const AthleteVolunteerRegistrationDetails = lazy(() =>
  import('Routes/Athlete/VolunteerRegistrations/Details')
);

const AthleteOrders = lazy(() => import('Routes/Athlete/Orders'));
const AthleteOrderDetails = lazy(() =>
  import('Routes/Athlete/Orders/OrderDetails')
);

const AthleteAcceptOrganizerTeamInvite = lazy(() =>
  import('Routes/Athlete/Invitations/Organizer/JoinTeam/AcceptInvitation')
);

const AthleteDashboard = lazy(() => import('Routes/Athlete/Dashboard'));

const ParticipantAcceptToS = lazy(() =>
  import('Routes/Participant/acceptTerms')
);

const LandingPage = lazy(() => import('Routes/LandingPage'));
// const EventPage = lazy(() => import('Routes/LandingPage/Events/Details'));
const EventInstancePage = lazy(() =>
  import('Routes/LandingPage/EventInstances/Details')
);

const Leaderboard = lazy(() => import('Routes/Displays/Leaderboard'));

// ############### SHOP ##############################################
const ShopProductsGridView = lazy(() => import('Routes/Shop/List'));

const ShopProductDetails = lazy(() => import('Routes/Shop/ProductDetails'));

const ShopProductsEventInstanceFeaturedGridView = lazy(() =>
  import('Routes/Shop/EventInstanceFeaturedItems')
);

const Parallax = lazy(() => import('Routes/LandingPage/ParallaxHeroSection'));
const ParallaxHeroSection = lazy(() =>
  // import('Routes/LandingPage/ParallaxHeroSection/index7')
  import('Routes/LandingPage/ParallaxHeroSection/index9')
);
const PaneOfGlass = lazy(() =>
  import('Routes/LandingPage/ParallaxHeroSection/index6_pane_of_glass')
);

const CreateOrganization = lazy(() =>
  import('Routes/LandingPage/CreateOrganization')
);

// const EventRegistration = lazy(() =>
//   import('Routes/LandingPage/EventRegistration')
// );

const AddNewEventRegistration = lazy(() =>
  import('Routes/LandingPage/EventRegistration/AddNewRegistration')
);

const ManageEventRegistration = lazy(() =>
  import('Routes/LandingPage/EventRegistration/ManageExistingRegistration')
);

const EventRegistrationPayment = lazy(() =>
  import('Routes/LandingPage/EventRegistration/paymentForm')
);
const EventRegistrationPaymentSuccess = lazy(() =>
  import('Routes/LandingPage/EventRegistration/paymentForm')
);

const AddNewVolunteerRegistration = lazy(() =>
  import('Routes/LandingPage/Volunteers/Registration')
);

const OrganizerDashboard = lazy(() => import('Routes/Organizer/Dashboard'));

const OrganizerEventCreate = lazy(() =>
  import('Routes/Organizer/Events/Create')
);
const OrganizerEventsList = lazy(() => import('Routes/Organizer/Events/List'));
const OrganizerEventDetails = lazy(() =>
  import('Routes/Organizer/Events/Details')
);

const OrganizerEventInstanceOverview = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Overview')
);

const OrganizerEventInstanceSetup = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Setup')
);
const OrganizerEventInstanceMerchandise = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Merchandise')
);

const OrganizerEventInstanceParticipants = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Participants')
);

const OrganizerEventInstanceCommunications = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Communications')
);

const OrganizerEventInstanceVolunteers = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Volunteers')
);

const OrganizerEventInstanceWorkouts = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Workouts')
);
const OrganizerEventInstanceJudging = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Judging')
);

const OrganizerEventInstanceCompetitionPrep = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/CompetitionPrep')
);
const OrganizerEventInstanceLeaderboard = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Leaderboard')
);

const OrganizerOrganizationSettings = lazy(() =>
  import('Routes/Organizer/Organization/Settings/General')
);

const OrganizerOrganizationTeamEditor = lazy(() =>
  import('Routes/Organizer/Organization/Team')
);
const OrganizerOrganizationSettingsPayments = lazy(() =>
  import('Routes/Organizer/Organization/Settings/Payments')
);

const OrganizerOrganizationSettingsMessageTemplates = lazy(() =>
  import('Routes/Organizer/Organization/Settings/MessageTemplates')
);

const OrganizerOrganizationBilling = lazy(() =>
  import('Routes/Organizer/Organization/Settings/Billing')
);

const OrganizerOrdersList = lazy(() => import('Routes/Organizer/Orders/List'));
const OrganizerOrderDetails = lazy(() =>
  import('Routes/Organizer/Orders/Details')
);

const OrganizerMerchandiseList = lazy(() =>
  import('Routes/Organizer/Merchandise/List')
);

const OrganizerMerchandiseEditProduct = lazy(() =>
  import('Routes/Organizer/Merchandise/Edit')
);

const OrganizerMerchandiseAddProduct = lazy(() =>
  import('Routes/Organizer/Merchandise/Add')
);

const OrganizerTaskList = lazy(() => import('Routes/Organizer/Tasks/List'));
const OrganizerTaskItem = lazy(() => import('Routes/Organizer/Tasks/TaskForm'));

const OrganizerJudgingList = lazy(() =>
  import('Routes/Organizer/Judging/List')
);
const OrganizerJudgingItem = lazy(() =>
  import('Routes/Organizer/Judging/Item')
);

const OrganizerAthleteCheckIn = lazy(() =>
  import('Routes/Organizer/AthleteCheckIn')
);

const OrganizerSpectatorTicketValidation = lazy(() =>
  import('Routes/Organizer/SpectatorTicketValidation')
);

const OrganizerScoring = lazy(() => import('Routes/Organizer/Scoring'));

const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;

const realm = new Realm.App(REALM_APP_ID);

// if (process.env.REACT_APP_ENV === 'PROD') {
//   // disable React Dev Tools for production
//   disableReactDevTools();

//   // deactivate  console logging on PROD
//   console.log = () => {};
// }

const tdu = `\n\n████████╗██████╗ ██╗   ██╗
╚══██╔══╝██╔══██╗██║   ██║
   ██║   ██║  ██║██║   ██║
   ██║   ██║  ██║██║   ██║
   ██║   ██████╔╝╚██████╔╝
   ╚═╝   ╚═════╝  ╚═════╝ \n\n`;

console.log(tdu);

// Gets a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
  // Guarantee that there's a logged in user with a valid access token

  // !ATTENTION, when creating a new REALM instance and / or Cognito User Pools, also change AUD requirement in JWT Auth configuration to new / prod cognito pool id's. Otherwise JWT login to REALM will fail.

  // when freshToken is defined, it means that there was a fresh login and a new token was provided during login. Logiut current user and use this token to log in to realm instead of using session id / jwt to obtain new access token.

  const getIdToken = async () =>
    new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            console.error("Error fetching user's id-token", err);
            reject(err);
          } else {
            // console.log('Fetched ID-Token:', session.idToken.jwtToken);
            resolve(session.idToken.jwtToken);
          }
        });
      } else {
        console.log('User currently not logged in to Cognito User Pool.');
        reject('No user logged in.');
      }
    });

  const logInToRealm = async () => {
    console.log('Realm: Logging in user');
    const idToken = await getIdToken().catch((error) =>
      console.error('ERROR getting Access Token from AWS Cognito:\n', error)
    );

    if (idToken) {
      // console.log('App.js: idToken to log into REALM: ', idToken);
      const loggedInUser = await realm
        .logIn(Realm.Credentials.jwt(idToken))
        .catch((error) => console.error('ERROR logging in to REALM:\n', error));
      console.log('App.js: Realm: User logged in:', loggedInUser);
    } else {
      console.log(
        'No ID-Token available, logging in to REALM as anonymous user with limited privileges.'
      );
      await realm.logIn(Realm.Credentials.anonymous());
    }
    // console.log('App.js: Realm: User logged in:', realm.currentUser);
    return realm.currentUser.accessToken;
  };

  const freshLogin = localStorage.getItem('freshLoginDetected');

  if (realm.currentUser && !freshLogin) {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.

    console.log(
      'App.js: Realm: User already logged in, refreshing token anyway.',
      realm.currentUser
    );
    // console.log('Using existing REALM login');
    await realm.currentUser.refreshCustomData().catch(async (err) => {
      await logInToRealm().catch((error) =>
        console.error('ERROR logging in to REALM:\n', error)
      );
    });
  } else {
    if (freshLogin) {
      console.log(
        'App.js: Realm: freshLoginDetected. Logging in with new user session id token.',
        freshLogin
      );
      localStorage.removeItem('freshLoginDetected');

      if (realm.currentUser) {
        console.log('App.js: Realm: Logging out previous user...');
        // await realm.currentUser.logOut();
      }
    } else {
      console.log('App.js: Realm: no user logged in. Logging in to Realm...');
    }
    await logInToRealm().catch((error) => {
      console.error('ERROR logging in to REALM:\n', error);
    });
    // await client.resetStore();
    // window.location.reload();
  }

  return realm.currentUser.accessToken;
}

// Configure the ApolloClient to connect to your app's GraphQL endpoint
const newAuthenticatedApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: `https://eu-central-1.aws.services.cloud.mongodb.com/api/client/v2.0/app/${REALM_APP_ID}/graphql`,
      // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
      // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
      // access token before sending the request.
      fetch: async (uri, options) => {
        let accessToken;

        accessToken = await getValidAccessToken().catch((error) =>
          console.error(
            'ERROR getting access token for Apollo Client:\n',
            error
          )
        );
        // if (accessToken) {
        options.headers.Authorization = `Bearer ${accessToken}`;
        return fetch(uri, options);
        // }
        // }
      }
    }),
    cache: new InMemoryCache()
  });
};

const client = newAuthenticatedApolloClient();

export default function App() {
  //   const Styles = makeStyles((theme) => ({
  //     variantError: {
  //       backgroundColor: theme.palette.error.main,
  //     },
  //     variantSuccess: {
  //       backgroundColor: theme.palette.success.main,
  //       // color: "#343434",
  //     },
  //   }));

  //   const classes = Styles();
  //   // const classes = {};

  const [controller, dispatch] = useArgonController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    darkSidenav,
    darkMode
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState('loading');

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin]
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <ArgonBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}>
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </ArgonBox>
  );

  // if not logged in, only open route to login page.

  useEffect(() => {
    getSession()
      .then((session) => {
        console.log('Session: ', session);
        setIsLoggedIn(true);
      })
      .catch((err) => {
        setIsLoggedIn(false);
      });
  }, []);

  // if (!isLoggedIn) {
  //   return (
  //     <>
  //       <ApolloProvider client={client}>
  //         <CacheProvider value={rtlCache}>
  //           <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
  //             <SnackbarProvider
  //               maxSnack={3}
  //               // classes={{
  //               // variantError: classes.variantError,
  //               // variantSuccess: classes.variantSuccess,
  //               // }}
  //             >
  //               <CssBaseline />
  //               <Routes>
  //                 <Route path='/' element={<LandingPage />} />
  //                 <Route path='/signup' element={<SignupPage />} />
  //                 <Route path='/login' element={<LoginPage />} />
  //                 <Route path='/logout' element={<LogoutPage />} />
  //                 <Route path='*' element={<Navigate to='/' redirect />} />
  //               </Routes>
  //               {/* <LoginPage /> */}
  //               {/* <Navigate replace to="/login" /> */}
  //             </SnackbarProvider>
  //           </ThemeProvider>
  //         </CacheProvider>
  //       </ApolloProvider>
  //     </>
  //   );
  // }

  // if (isLoggedIn === 'loading') {
  //   <Loader />;
  // }

  if (!client) return <Loader />;

  // if (isLoggedIn === true)
  // return direction === 'rtl' ? (
  //   <ApolloProvider client={client}>
  //     <CacheProvider value={rtlCache}>
  //       <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
  //         <SnackbarProvider
  //           maxSnack={3}
  //           classes={
  //             {
  //               // variantError: classes.variantError,
  //               // variantSuccess: classes.variantSuccess,
  //             }
  //           }
  //         >
  //           <CssBaseline />
  //           {layout === 'dashboard' && (
  //             <>
  //               <Sidenav
  //                 color={sidenavColor}
  //                 brand={darkSidenav || darkMode ? brand : brandDark}
  //                 brandName=''
  //                 routes={routes}
  //                 onMouseEnter={handleOnMouseEnter}
  //                 onMouseLeave={handleOnMouseLeave}
  //               />
  //               <Configurator />
  //               {configsButton}
  //             </>
  //           )}
  //           {layout === 'vr' && <Configurator />}
  //           <Routes>
  //             {getRoutes(routes)}
  //             <Route path='/' element={<LandingPage />} />
  //             <Route path='/Organizer/events/:id' element={<EventDetails />} />
  //             <Route path='/login' element={<LoginPage />} />
  //             <Route path='/logout' element={<LogoutPage />} />
  //             <Route path='/signup' element={<SignupPage />} />
  //             <Route path='/athlete/profile' element={<AthleteProfile />} />
  //             <Route path='/events/:id' element={<EventPage />} />
  //             <Route
  //               path='/events/:id/registration'
  //               element={<EventRegistration />}
  //             />
  //             <Route
  //               path='/events/:id/registration/:registrationId'
  //               element={<EventRegistration />}
  //             />
  //             <Route
  //               path='/events/:id/registration/:registrationId/payment'
  //               element={<EventRegistrationPayment />}
  //             />
  //             <Route
  //               path='/events/:id/registration/:registrationId/payment-success'
  //               element={<EventRegistrationPaymentSuccess />}
  //             />
  //             <Route path='*' element={<Navigate to='/' />} />
  //           </Routes>
  //         </SnackbarProvider>
  //       </ThemeProvider>
  //     </CacheProvider>
  //   </ApolloProvider>
  // ) : (

  // let responsiveTheme = responsiveFontSizes(theme);

  return (
    <>
      <ScrollToTop />
      <Suspense
        fallback={
          <>
            <ThemeProvider theme={darkMode ? themeDark : theme}>
              <Loader />
            </ThemeProvider>
          </>
        }>
        <ApolloProvider client={client}>
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <SnackbarProvider
              maxSnack={3}
              classes={
                {
                  // variantError: classes.variantError,
                  // variantSuccess: classes.variantSuccess,
                }
              }>
              <CssBaseline />

              {/* {layout === 'dashboard' && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brand={darkSidenav || darkMode ? brand : brandDark}
                    brandName='Argon Dashboard 2 PRO'
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                  <Configurator />
                  {configsButton}
                </>
              )}
              {layout === 'vr' && <Configurator />} */}
              {/* <ArgonBox display='flex' flexDirection='column' minHeight='100vh'> */}
              <Routes>
                {/* {getRoutes(routes)} */}
                {/* <Route path="/" element={<LandingPage />} /> */}
                <Route path="/" element={<ParallaxHeroSection />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route
                  path="/forgot-password"
                  element={<ForgotPasswordPage />}
                />
                <Route path="/legal/imprint" element={<Imprint />} />
                <Route path="/legal/privacy" element={<Privacy />} />
                <Route
                  path="/legal/terms-of-service"
                  element={<TermsOfService />}
                />

                {/* ###### LINK TREE ##################################################### */}
                <Route path="/trees/book-demo" element={<LinkTreeDemo />} />

                {/* ###### LANDING PAGE ##################################################### */}
                <Route
                  path="/create-organization"
                  element={<CreateOrganization />}
                />
                <Route
                  path="/create-organisation"
                  element={<CreateOrganization />}
                />
                <Route path="/parallax" element={<ParallaxHeroSection />} />
                <Route path="/paneofglass" element={<PaneOfGlass />} />
                {/* ###### ATHLETE ##################################################### */}
                <Route path="/athlete/profile" element={<AthleteProfile />} />
                <Route
                  path="/athlete/registrations"
                  element={<AthleteRegistrations />}
                />
                <Route
                  path="/athlete/spectator-tickets"
                  element={<AthleteSpectatorTickets />}
                />
                <Route
                  path="/athlete/spectator-tickets/:ticketId"
                  element={<AthleteSpectatorTicketDetails />}
                />
                <Route path="/athlete/orders" element={<AthleteOrders />} />
                <Route
                  path="/athlete/orders/:orderId"
                  element={<AthleteOrderDetails />}
                />

                <Route
                  path="/athlete/volunteer-registrations"
                  element={<AthleteVolunteerRegistrations />}
                />
                <Route
                  path="/athlete/volunteer-registrations/:volunteerRegistrationId"
                  element={<AthleteVolunteerRegistrationDetails />}
                />

                <Route
                  path="/athlete/dashboard"
                  element={<AthleteDashboard />}
                />
                <Route
                  path="/athlete/invitations/organizer-team/:invitationId"
                  element={<AthleteAcceptOrganizerTeamInvite />}
                />
                {/* ###### PARTICIPANTS ##################################################### */}
                <Route
                  path="/participant/accept-terms/:participantId/:token"
                  element={<ParticipantAcceptToS />}
                />
                {/* ###### EVENTS ##################################################### */}
                <Route
                  path="/events/:eventInstanceId"
                  element={<EventInstancePage />}
                />
                <Route
                  path="/events/:eventInstanceId/:section"
                  element={<EventInstancePage />}
                />
                {/* <Route
                  path="/events/:eventInstanceId/registration"
                  element={<EventRegistration />}
                /> */}
                <Route
                  path="/events/:eventInstanceId/registration"
                  element={<AddNewEventRegistration />}
                />
                <Route
                  path="/events/:eventInstanceId/registration/:registrationId"
                  element={<ManageEventRegistration />}
                />
                <Route
                  path="/events/:eventInstanceId/registration/:registrationId/payment"
                  element={<EventRegistrationPayment />}
                />
                <Route
                  path="/events/:eventInstanceId/registration/:registrationId/payment-success"
                  element={<EventRegistrationPaymentSuccess />}
                />
                <Route
                  path="/display/leaderboard/:eventInstanceId"
                  element={<Leaderboard />}
                />
                {/* ###### REGISTRATIONS ##################################################### */}
                <Route
                  path="/registration/:registrationId"
                  element={<ManageEventRegistration />}
                />
                {/* ###### SHOP ##################################################### */}
                <Route path="/shop/" element={<ShopProductsGridView />} />
                <Route
                  path="/shop/product/:productId"
                  element={<ShopProductDetails />}
                />
                <Route
                  path="/shop/:productCategoryId"
                  element={<ShopProductsGridView />}
                />
                <Route
                  path="/shop/:organizationId/:productCategoryId"
                  element={<ShopProductsGridView />}
                />
                <Route
                  path="/shop/eventInstance/:eventInstanceId"
                  element={<ShopProductsEventInstanceFeaturedGridView />}
                />

                {/* ###### VOLUNTEERS ##################################################### */}
                <Route
                  path="/events/:eventInstanceId/volunteers/registration"
                  element={<AddNewVolunteerRegistration />}
                />

                <Route
                  path="/events/:eventInstanceId/volunteers/registration/:volunteerPositionId"
                  element={<AddNewVolunteerRegistration />}
                />

                {/* ###### CHECKOUT ##################################################### */}
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route
                  path="/checkout/shipping"
                  element={<ShippingAndBillingAddressCollection />}
                />
                {/* ###### Organizer ######################################################## */}
                <Route
                  path="/organizer"
                  element={<Navigate to="/organizer/dashboard" />}
                />
                <Route
                  path="/organizer/dashboard"
                  element={<OrganizerDashboard />}
                />
                <Route
                  path="/organizer/organization/settings/general"
                  element={<OrganizerOrganizationSettings />}
                />
                <Route
                  path="/organizer/organization/settings/payments"
                  element={<OrganizerOrganizationSettingsPayments />}
                />
                <Route
                  path="/organizer/organization/settings/message-templates"
                  element={<OrganizerOrganizationSettingsMessageTemplates />}
                />

                <Route
                  path="/organizer/organization/settings/billing"
                  element={<OrganizerOrganizationBilling />}
                />

                <Route
                  path="/organizer/organization/settings/team"
                  element={<OrganizerOrganizationTeamEditor />}
                />
                <Route
                  path="/organizer/events"
                  element={<OrganizerEventsList />}
                />
                <Route
                  path="/organizer/events/create"
                  element={<OrganizerEventCreate />}
                />
                <Route
                  path="/organizer/events/:eventId"
                  element={<OrganizerEventDetails />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId"
                  element={<OrganizerEventInstanceOverview />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/setup"
                  element={<OrganizerEventInstanceSetup />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/participants"
                  element={<OrganizerEventInstanceParticipants />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/workouts"
                  element={<OrganizerEventInstanceWorkouts />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/judging"
                  element={<OrganizerEventInstanceJudging />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/overview"
                  element={<OrganizerEventInstanceOverview />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/merchandise"
                  element={<OrganizerEventInstanceMerchandise />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/financials"
                  element={<OrganizerEventInstanceSetup />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/communications"
                  element={<OrganizerEventInstanceCommunications />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/volunteers"
                  element={<OrganizerEventInstanceVolunteers />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/volunteers/:volunteerId"
                  element={<OrganizerEventInstanceVolunteers />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/leaderboard"
                  element={<OrganizerEventInstanceLeaderboard />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/competitionPrep"
                  element={<OrganizerEventInstanceCompetitionPrep />}
                />
                <Route
                  path="/organizer/orders"
                  element={<OrganizerOrdersList />}
                />
                <Route
                  path="/organizer/orders/:orderId"
                  element={<OrganizerOrderDetails />}
                />
                <Route
                  path="/organizer/merchandise"
                  element={<OrganizerMerchandiseList />}
                />
                <Route
                  path="/organizer/merchandise/:productId"
                  element={<OrganizerMerchandiseEditProduct />}
                />
                <Route
                  path="/organizer/merchandise/add"
                  element={<OrganizerMerchandiseAddProduct />}
                />
                <Route
                  path="/organizer/tasks"
                  element={<OrganizerTaskList />}
                />
                <Route
                  path="/organizer/tasks/:taskid"
                  element={<OrganizerTaskItem />}
                />
                <Route
                  path="/organizer/judging"
                  element={<OrganizerJudgingList />}
                />
                <Route
                  path="/organizer/judging/:scoreId"
                  element={<OrganizerJudgingItem />}
                />
                <Route
                  path="/organizer/scoring"
                  element={<OrganizerScoring />}
                />
                <Route
                  path="/organizer/scoring/:eventId"
                  element={<OrganizerScoring />}
                />
                <Route
                  path="/organizer/scoring/:eventId/:eventInstanceId"
                  element={<OrganizerScoring />}
                />
                <Route
                  path="/organizer/scoring/:eventId/:eventInstanceId/:workoutId"
                  element={<OrganizerScoring />}
                />
                <Route
                  path="/organizer/scoring/:eventId/:eventInstanceId/:workoutId/:heatIndex"
                  element={<OrganizerScoring />}
                />

                <Route
                  path="/organizer/athlete-check-in"
                  element={<OrganizerAthleteCheckIn />}
                />
                <Route
                  path="/organizer/athlete-check-in/:eventId"
                  element={<OrganizerAthleteCheckIn />}
                />
                <Route
                  path="/organizer/athlete-check-in/:eventId/:eventInstanceId"
                  element={<OrganizerAthleteCheckIn />}
                />

                <Route
                  path="/organizer/spectator-ticket-validation"
                  element={<OrganizerSpectatorTicketValidation />}
                />
                <Route
                  path="/organizer/spectator-ticket-validation/:eventId"
                  element={<OrganizerSpectatorTicketValidation />}
                />
                <Route
                  path="/organizer/spectator-ticket-validation/:eventId/:eventInstanceId"
                  element={<OrganizerSpectatorTicketValidation />}
                />
                <Route
                  path="/organizer/spectator-ticket-validation/ticket/:ticketId"
                  element={<OrganizerSpectatorTicketValidation />}
                />

                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
              {/* </ArgonBox> */}
            </SnackbarProvider>
          </ThemeProvider>
        </ApolloProvider>
      </Suspense>

      {/* <ChatWidget
        // `accountId` is used instead of `token` in older versions
        // of the @papercups-io/chat-widget package (before v1.2.x).
        // You can delete this line if you are on the latest version.
        // accountId="2c2805bf-d863-41cc-8e92-03ce5433e684"
        token="2c2805bf-d863-41cc-8e92-03ce5433e684"
        title="Welcome to STRONG ONE"
        subtitle="Ask us anything in the chat window below 😊"
        primaryColor="#0693e3"
        greeting="Hey there! How may I help you?"
        awayMessage="We will get back to you as soon as we can!"
        newMessagePlaceholder="Start typing..."
        showAgentAvailability={true}
        agentAvailableText="We're online right now!"
        agentUnavailableText="We're away at the moment."
        requireEmailUpfront={false}
        iconVariant="outlined"
        baseUrl="https://chat.crsft.com"
        // Optionally include data about your customer here to identify them
        // customer={{
        //   name: __CUSTOMER__.name,
        //   email: __CUSTOMER__.email,
        //   external_id: __CUSTOMER__.id,
        //   metadata: {
        //     plan: "premium"
        //   }
        // }}
      /> */}
    </>
  );
}
