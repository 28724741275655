import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  FormControl,
  FormLabel,
  Divider
} from '@mui/material';
import ArgonTypography from 'components/ArgonTypography';
import ArgonBox from 'components/ArgonBox';

const ListSelector = ({
  items = [],
  selectedItems = [],
  onSelectionChange = () => {},
  label
}) => {
  const handleToggle = (value) => {
    const currentIndex = selectedItems.findIndex(
      (item) => item.value === value
    );
    const newSelected = [...selectedItems];

    if (currentIndex === -1) {
      // Add the item to selection
      const itemToAdd = items.find((item) => item.value === value);
      newSelected.push(itemToAdd);
    } else {
      // Remove the item from selection
      newSelected.splice(currentIndex, 1);
    }

    onSelectionChange(newSelected);
  };

  const handleToggleAll = () => {
    if (selectedItems.length === items.length) {
      // If all items are selected, deselect all
      onSelectionChange([]);
    } else {
      // If not all items are selected, select all
      onSelectionChange([...items]);
    }
  };

  const allSelected = items.length > 0 && selectedItems.length === items.length;

  return (
    <>
      <ArgonBox ml={0.5}>
        <ArgonTypography variant="caption" color="dark" fontWeight="bold">
          {label}
        </ArgonTypography>
      </ArgonBox>
      {/* <ArgonBox ml={1.5}> */}
      <FormControl fullWidth sx={{ mt: 1 }}>
        <List sx={{ width: '100%', py: 0 }}>
          <ListItem
            dense
            button
            onClick={handleToggleAll}
            sx={{
              borderBottom: '1px solid #eee'
            }}>
            <ListItemIcon sx={{ minWidth: 40, marginLeft: 1.5 }}>
              <Checkbox
                edge="start"
                checked={allSelected}
                indeterminate={selectedItems.length > 0 && !allSelected}
                tabIndex={-1}
                disableRipple
                sx={{ p: 0.5 }}
              />
            </ListItemIcon>

            <ListItemText
              primary={
                <ArgonTypography variant="regular" fontWeight="regular">
                  {allSelected ? 'Deselect All' : 'Select All'}
                </ArgonTypography>
              }
            />
          </ListItem>

          {items.map((item) => {
            const isSelected = selectedItems.some(
              (selected) => selected.value === item.value
            );

            return (
              <ListItem
                key={item.value}
                dense
                button
                onClick={() => handleToggle(item.value)}>
                <ListItemIcon sx={{ minWidth: 40, marginLeft: 1.5 }}>
                  <Checkbox
                    edge="start"
                    checked={isSelected}
                    tabIndex={-1}
                    disableRipple
                    sx={{ p: 0.5 }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <ArgonTypography variant="regular">
                      {item.label}
                    </ArgonTypography>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </FormControl>
      {/* </ArgonBox> */}
    </>
  );
};

ListSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default ListSelector;
