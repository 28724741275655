import { gql } from '@apollo/client';
import { USER_FRAGMENT } from './fragments';

export const USER_INSERT = gql`
  mutation InsertUser($data: UserInsertInput!) {
    insertOneUser(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_UPDATE = gql`
  mutation UpdateUser($userId: ObjectId!, $data: UserUpdateInput!) {
    updateOneUser(query: { _id: $userId }, set: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
