import React from 'react';
import Grid from '@mui/material/Grid';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonSelect from 'components/ArgonSelect';
import FormField from 'layouts/pages/account/components/FormField';
import selectData from 'options/selectData';
import ArgonEditor from 'components/ArgonEditor';

function BasicInfo({ formik, editor1Key }) {
  const handleSelectMenuChanged = (fieldName, option) => {
    formik.setFieldValue(fieldName, option);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%">
          <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold">
              Event Instance Type
            </ArgonTypography>
          </ArgonBox>
          <ArgonSelect
            id="sports"
            name="sports"
            placeholder="Please choose"
            options={selectData.eventInstanceTypes}
            value={selectData.eventInstanceTypes.filter(
              (option) => option.value === formik.values.type
            )}
            onChange={(option) => handleSelectMenuChanged('type', option.value)}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
          />
        </ArgonBox>
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormField
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          label="Name (optional)"
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          placeholder="You can add a name / identifier for your event instance here"
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize">
            Description (optional)
          </ArgonTypography>
        </ArgonBox>
        <ArgonEditor
          id="description"
          name="description"
          uniqueKey={editor1Key}
          value={formik.values.description}
          onChange={(value) => formik.setFieldValue('description', value)}
          placeholder="You can add a desciption for your event instance here"
        />
      </Grid>
    </Grid>
  );
}

export default BasicInfo;
