import React, { useEffect, useState } from 'react';
import DialogWrapper from 'components/DialogWrapper';
import { EVENTINSTANCES_GET_BY_EVENTID } from 'GraphQL/EventInstances/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import ArgonSelect from 'components/ArgonSelect';
import LoaderWrap from 'LoaderWrap';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import { EVENTINSTANCE_DIVISION_CREATE } from 'GraphQL/EventInstances/mutations';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import { stripeCreateNewPrice } from 'services/REST_API/stripe';
import { FormatMoney } from 'format-money-js';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2';
import selectData from 'options/selectData';
import ListSelector from 'components/ListSelector';
import { Collapse } from '@mui/material';

const CloneDivisionsDialog = ({ currentEventInstance, open, onClose }) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  console.log(
    'CloneDivisionsDialog: currentEventInstance: ',
    currentEventInstance
  );

  const [selectedEventInstance, setSelectedEventInstance] = useState(null);
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [availableDivisions, setAvailableDivisions] = useState([]);
  const [workInProgress, setWorkInProgress] = useState(false);

  const getDecimalSeparator = (locale) => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale || i18n.language)
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === 'decimal').value;
  };

  const fm = new FormatMoney({
    decimals: 2,
    locale: i18n.language,
    decimalPoint: getDecimalSeparator(i18n.language)
  });

  const [fetchEventInstances, { data, loading }] = useLazyQuery(
    EVENTINSTANCES_GET_BY_EVENTID,
    {
      onCompleted: (data) => {
        console.log(
          'CloneDivisions: Event Instances received from Database: ',
          data
        );
      },
      onError: (error) => {
        console.error(
          'CloneDivisions: Error getting event instances from Database: ',
          error
        );
      }
    }
  );

  const [fetchEventInstanceDivisions, { loading: divisionsLoading }] =
    useLazyQuery(EVENTINSTANCE_GET_BY_ID, {
      onCompleted: (data) => {
        console.log(
          'CloneDivisions: Event Instance Divisions received from Database: ',
          data
        );
        const divisions = data?.eventInstance?.divisions || [];
        const formattedDivisions = divisions.map((div) => ({
          value: div._id,
          label: div.name,
          ...div
        }));
        setAvailableDivisions(formattedDivisions);
      },
      onError: (error) => {
        console.error(
          'CloneDivisions: Error getting event instance divisions: ',
          error
        );
        enqueueSnackbar('Error fetching divisions', { variant: 'error' });
      }
    });

  const [createDivision] = useMutation(EVENTINSTANCE_DIVISION_CREATE, {
    onCompleted: (data) => {
      enqueueSnackbar(
        `Division "${data?.EventInstanceDivisionCreate?.name}" has been created!`,
        { variant: 'success' }
      );
    },
    onError: (error) => {
      console.error('onError: ', error);
      enqueueSnackbar(`Error creating division!`, { variant: 'error' });
    },
    refetchQueries: [
      {
        query: EVENTINSTANCE_GET_BY_ID,
        variables: { eventInstanceId: currentEventInstance?._id }
      }
    ]
  });

  useEffect(() => {
    if (currentEventInstance?.event?._id) {
      fetchEventInstances({
        variables: { eventId: currentEventInstance?.event?._id }
      });
    }
  }, [currentEventInstance]);

  const handleClose = () => {
    setSelectedEventInstance(null);
    setSelectedDivisions([]);
    setAvailableDivisions([]);
    onClose && onClose();
  };

  const handleEventInstanceSelect = (option) => {
    console.log('CloneDivisions: handleEventInstanceSelect', option);
    setSelectedEventInstance(option);
    setSelectedDivisions([]);
    if (option?.value) {
      fetchEventInstanceDivisions({
        variables: { eventInstanceId: option.value }
      });
    } else {
      setAvailableDivisions([]);
    }
  };

  const handleCloneDivisions = async () => {
    if (selectedDivisions.length === 0) return;

    console.log('CloneDivisions: handleCloneDivisions', selectedDivisions);

    Swal.fire({
      title: 'Confirm',
      text: `Please confirm that you want to clone ${selectedDivisions.length} division(s). New divisions will be created with the same settings as the original divisions. Existing divisions will remain unchanged.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, clone divisions!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setWorkInProgress(true);
        await cloneDivisions(selectedDivisions);
        setWorkInProgress(false);
        handleClose();
      }
    });
  };

  const cloneDivisions = async (divisionsToClone) => {
    console.log('CloneDivisions: divisionsToClone: ', divisionsToClone);

    for (const division of divisionsToClone) {
      console.log('CloneDivisions: Processing division: ', division);
      let stripePriceId = division?.stripePriceId;

      if (currentEventInstance?.event?.stripeProductId) {
        console.log(
          'EventInstance has a stripe product, now taking care of prices...'
        );
        if (
          Number(division?.registrationFee) !==
            fm.un(division?.registrationFee) * 100 ||
          !stripePriceId
        ) {
          console.log(
            'No previous price for division or price has changed. Updating price...'
          );
          await stripeCreateNewPrice({
            organizationId: currentEventInstance?.organizationId,
            productId: currentEventInstance?.stripeProductId,
            priceInCents: fm.un(division?.registrationFee) * 100,
            currency:
              currentEventInstance?.currency ||
              currentEventInstance?.event?.currency ||
              currentEventInstance?.event?.organization?.currency ||
              'EUR'
          })
            .then((res) => {
              console.log('Created new price: ', res);
              stripePriceId = res?.id;
            })
            .catch((err) => {
              console.error(err);
              enqueueSnackbar(
                'Something went wrong while creating price in Stripe. Please add this division manually.',
                { variant: 'error' }
              );
            });
        }
      } else {
        console.log(
          'EventInstance has no stripe product, not taking care of prices in stripe...'
        );
      }

      const newDivisionParameters = {
        data: {
          eventInstanceId: currentEventInstance._id,
          division: {
            name: division.name,
            description: division.description,
            maxParticipants: division.maxParticipants,
            forIndividuals: division.forIndividuals,
            forTeams: division.forTeams,
            forUnderage: division.forUnderage,
            teamSize: division.teamSize,
            minAge: division.minAge,
            maxAge: division.maxAge,
            registrationFee: division.registrationFee,
            advancingAthletesNumber: division.advancingAthletesNumber,
            advancingAthletesPercentage: division.advancingAthletesPercentage,
            assignedJudges: division.assignedJudges,
            sequence: division.sequence,
            stripePriceId: stripePriceId
          }
        }
      };

      console.log(
        'CloneDivisions: newDivisionParameters: ',
        newDivisionParameters
      );

      await createDivision({
        variables: newDivisionParameters
      });
    }
    return true;
  };

  return (
    <DialogWrapper
      title="Clone Divisions"
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm">
      <LoaderWrap loading={loading || divisionsLoading}>
        <ArgonSelect
          id="event-instance-select"
          name="event-instance-select"
          overflow
          label="Choose Event Instance To Clone From"
          value={selectedEventInstance}
          options={data?.eventInstances
            ?.filter(
              (eventInstance) => eventInstance._id !== currentEventInstance._id
            )
            .map((eventInstance) => ({
              label:
                eventInstance.name ||
                selectData.eventInstanceTypes.find(
                  (eit) => eit.value === eventInstance.type
                )?.label ||
                eventInstance.type,
              value: eventInstance._id
            }))}
          onChange={handleEventInstanceSelect}
        />

        <Collapse in={selectedEventInstance != null}>
          <ArgonBox mt={2}>
            <ListSelector
              label="Select Divisions To Clone"
              items={availableDivisions}
              selectedItems={selectedDivisions}
              onSelectionChange={setSelectedDivisions}
            />
          </ArgonBox>
        </Collapse>

        <ArgonBox mt={3} mb={0} display="flex" justifyContent="flex-end">
          <ArgonButton
            variant="gradient"
            color="success"
            size="small"
            loading={workInProgress}
            disabled={selectedDivisions.length === 0}
            onClick={handleCloneDivisions}>
            Clone Selected Divisions
          </ArgonButton>
        </ArgonBox>
      </LoaderWrap>
    </DialogWrapper>
  );
};

export default CloneDivisionsDialog;
