import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DialogWrapper from 'components/DialogWrapper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Collapse, Grid } from '@mui/material';
import ArgonInput from 'components/ArgonInput';
import FormikTextInput from 'components/FormikTextInput';
import ArgonDatePicker from 'components/ArgonDatePicker';
import { useTranslation } from 'react-i18next';
import FormikNumberInput from 'components/FormikNumberInput';
import ArgonCurrencyInput from 'components/ArgonCurrencyInput';
import ArgonButton from 'components/ArgonButton';
import ArgonBox from 'components/ArgonBox';
import Swal from 'sweetalert2';
import ObjectID from 'bson-objectid';
import { FormatMoney } from 'format-money-js';
import CurrencyInput from 'react-currency-input-field';
import ArgonTypography from 'components/ArgonTypography';
import InputMask from 'react-input-mask';
import { calculateSpectatorTicketPriceWithoutFee } from 'services/calculateFees';
import { calculateSpectatorTicketPriceWithFee } from 'services/calculateFees';
import FormikYesNoInput from 'components/FormikYesNoInput';

function VolunteerPositionForm({
  volunteerPosition,
  defaultStartDate,
  defaultEndDate,
  currency,
  onSave,
  onDelete,
  onClose,
  addNew
}) {
  const { t, i18n } = useTranslation();

  const [price, setPrice] = useState(0);

  console.log('VolunteersForm', volunteerPosition);

  console.log('VolunteersForm: defaultStartDate', new Date(defaultStartDate));
  console.log('VolunteersForm: defaultEndDate', new Date(defaultEndDate));

  const getDecimalSeparator = (locale) => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale || i18n.language)
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === 'decimal').value;
  };

  const fm = new FormatMoney({
    decimals: 2,
    locale: i18n.language,
    decimalPoint: getDecimalSeparator(i18n.language)
  });

  useEffect(() => {
    if (volunteerPosition) {
      formik.resetForm();
      formik.setValues(volunteerPosition);
    }
  }, [volunteerPosition]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    requirements: Yup.string().optional(),
    compensation: Yup.string().optional(),
    requiredCapacity: Yup.number().required('Required capacity is required'),
    startDate: Yup.string().required('Start date is required'),
    endDate: Yup.string().required('End date is required'),
    selectDays: Yup.boolean().optional(),
    requestAdditionalInfo: Yup.boolean().optional(),
    additionalInfoQuestion: Yup.string().optional(),
    fullAddressRequired: Yup.boolean().optional(),
    overnightAccomodationOffered: Yup.boolean().optional()
  });

  const formik = useFormik({
    initialValues: {
      _id: volunteerPosition?._id || new ObjectID().toHexString(),
      name: volunteerPosition?.name || '',
      description: volunteerPosition?.description || '',
      requirements: volunteerPosition?.requirements || '',
      compensation: volunteerPosition?.compensation || '',
      requiredCapacity: volunteerPosition?.requiredCapacity || 0,
      startDate: volunteerPosition?.startDate
        ? new Date(volunteerPosition.startDate)
        : defaultStartDate
        ? new Date(defaultStartDate)
        : null,
      endDate: volunteerPosition?.endDate
        ? new Date(volunteerPosition.endDate)
        : defaultEndDate
        ? new Date(defaultEndDate)
        : null,
      selectDays: volunteerPosition?.selectDays || false,
      requestAdditionalInfo: volunteerPosition?.requestAdditionalInfo || false,
      requestExperience: volunteerPosition?.requestExperience || false,
      additionalInfoQuestion: volunteerPosition?.additionalInfoQuestion || '',
      fullAddressRequired: volunteerPosition?.fullAddressRequired || false,
      overnightAccomodationOffered:
        volunteerPosition?.overnightAccomodationOffered || false
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const newValues = {
        ...values

        // compensation: fm.un(values.compensation) * 100
      };

      console.log('VolunteersForm: Saving values:', newValues);
      onSave(newValues);
    }
  });

  console.log('VolunteersForm: Formik values', formik.values);
  console.log('VolunteersForm: Formik errors', formik.errors);

  const handleDatePickerChanged = (fieldName, newValue) => {
    console.log(fieldName, newValue[0]);
    if (newValue.length > 0) {
      formik.setFieldValue(fieldName, newValue[0]);
    } else {
      console.error(
        'New picked date is an array with multiple dates. Cannot update.'
      );
    }
  };

  const handleDeleteButtonClicked = () => {
    Swal.fire({
      title: 'Delete Volunteer Position?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete && onDelete(volunteerPosition?._id);
        onClose && onClose();
      }
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormikTextInput
          fullWidth
          id="name"
          name="name"
          required
          label="Name of Position"
          formik={formik}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextInput
          fullWidth
          id="description"
          name="description"
          required
          multiline
          minRows={3}
          maxRows={6}
          label="Description"
          formik={formik}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextInput
          fullWidth
          id="requirements"
          name="requirements"
          label="Requirements for position"
          multiline
          minRows={3}
          maxRows={6}
          formik={formik}
        />
      </Grid>

      <Grid item xs={12}>
        <FormikYesNoInput
          fullWidth
          id="requestExperience"
          name="requestExperience"
          label="Ask Vounteer to describe his experience / qualifications"
          required
          formik={formik}
          yesInfo="Volunteer is asked to describe his experience / qualifications"
          noInfo="Volunteer does not need to describe his experience / qualifications"
        />
      </Grid>

      <Grid item xs={6}>
        <ArgonBox ml={0.5}>
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
            // color={textColor}
          >
            Start Date &nbsp;
            <span style={{ color: 'red' }}>*</span>
          </ArgonTypography>
        </ArgonBox>
        <ArgonDatePicker
          id="startDate"
          name="startDate"
          // defaultValue={formik.values.startDate}
          //   label="Ticket is valid on (date)"
          options={{
            allowInput: true,
            enableTime: true,
            time_24hr: true,
            defaultHour: 8
          }}
          input={{
            // size: 'small',
            placeholder: 'Select a date',
            error: formik.touched.startDate && Boolean(formik.errors.startDate),

            helperText: formik.touched.validDate && formik.errors.validDate
          }}
          onChange={(date) => {
            handleDatePickerChanged('startDate', date);
            // if (!formik.values.endDate || formik.values.endDate === '') {
            console.log(
              'VolunteersForm: Setting endDate to startDate',
              formik.values.endDate
            );
            handleDatePickerChanged('endDate', date);
            // }
          }}
          value={formik.values.startDate}
          // defaultValue={formik.values.validDate}
        />
      </Grid>

      <Grid item xs={6}>
        <ArgonBox ml={0.5}>
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
            // color={textColor}
          >
            End Date &nbsp;
            <span style={{ color: 'red' }}>*</span>
          </ArgonTypography>
        </ArgonBox>
        <ArgonDatePicker
          id="endDate"
          name="endDate"
          // defaultValue={formik.values.startDate}
          //   label="Ticket is valid on (date)"
          options={{
            allowInput: true,
            enableTime: true,
            time_24hr: true,
            defaultHour: 19
          }}
          input={{
            // size: 'small',
            placeholder: 'Select a date',
            error: formik.touched.endDate && Boolean(formik.errors.endDate),

            helperText: formik.touched.endDate && formik.errors.endDate
          }}
          onChange={(date) => {
            handleDatePickerChanged('endDate', date);
          }}
          value={formik.values.endDate}
          //   defaultValue={formik.values.validDate}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Collapse
          in={
            new Date(formik.values.startDate).toDateString() !==
            new Date(formik.values.endDate).toDateString()
          }>
          <FormikYesNoInput
            fullWidth
            id="selectDays"
            name="selectDays"
            label="Volunteer can select individual days"
            required
            formik={formik}
            yesInfo="Volunteer will select individual days to be present"
            noInfo="Volunteer must be present for the entire period"
          />
        </Collapse>
      </Grid> */}

      <Grid item xs={6}>
        <ArgonCurrencyInput
          id="compensation"
          name="compensation"
          label="Compensation offered"
          intlConfig={{
            locale: i18n.language,
            currency: currency || 'EUR'
          }}
          allowNegativeValue={false}
          // placeholder=""
          defaultValue={fm.from(formik.values?.compensation / 100)}
          decimalScale={2}
          // value={fm.from(formik.values?.compensation / 100)}
          onValueChange={(value, name) => {
            console.log(
              'VolunteersForm: onValueChange:',
              fm.un(value) * 100,
              name
            );
            formik.setFieldValue(name, fm.un(value) * 100, true);
          }}
          error={
            formik.touched.compensation && Boolean(formik.errors.compensation)
          }
          // transformRawValue={(value) => (value * 100).toString()}
        />
      </Grid>

      <Grid item xs={6}>
        <FormikYesNoInput
          fullWidth
          id="overnightAccomodationOffered"
          name="overnightAccomodationOffered"
          label="Overnight accomodation offered"
          required
          formik={formik}
        />
      </Grid>

      <Grid item xs={6}>
        <FormikNumberInput
          fullWidth
          min={0}
          id="requiredCapacity"
          name="requiredCapacity"
          label="Number of required volunteers"
          required
          formik={formik}
        />
      </Grid>

      <Grid item xs={6}>
        <FormikYesNoInput
          fullWidth
          id="fullAddressRequired"
          name="fullAddressRequired"
          label="Full volunteer address required"
          required
          formik={formik}
        />
      </Grid>

      <Grid item xs={12}>
        <FormikYesNoInput
          fullWidth
          id="requestAdditionalInfo"
          name="requestAdditionalInfo"
          label="Request additional information from volunteer"
          formik={formik}
        />
        {/* </Grid>

      <Grid item xs={12}> */}
        <Collapse in={formik.values.requestAdditionalInfo}>
          <FormikTextInput
            fullWidth
            id="additionalInfoQuestion"
            name="additionalInfoQuestion"
            label="Additional information question"
            multiline
            minRows={1}
            maxRows={3}
            required
            formik={formik}
          />
        </Collapse>
      </Grid>

      <Grid item xs={12}>
        <ArgonBox mt={2} display="flex" justifyContent="space-between">
          <ArgonButton
            color="secondary"
            variant="outlined"
            onClick={() => onClose && onClose()}>
            Cancel
          </ArgonButton>
          <ArgonBox display="flex" flexDirection="row" gap={1}>
            {!addNew && (
              <ArgonButton
                color="error"
                variant="outlined"
                onClick={() => handleDeleteButtonClicked()}>
                Delete
              </ArgonButton>
            )}
            <ArgonButton
              color="success"
              variant="gradient"
              onClick={formik.handleSubmit}>
              Done
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </Grid>
    </Grid>
  );
}

VolunteerPositionForm.propTypes = {};

export default VolunteerPositionForm;
