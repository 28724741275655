import { gql } from '@apollo/client';
import { USER_FRAGMENT } from './fragments';

export const USERS_GET_ALL = gql`
  query {
    users {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_GET_BY_ID = gql`
  query getUserById($userId: ObjectId!) {
    user(query: { _id: $userId }) {
      _id
      email
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
