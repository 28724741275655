import React, { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

import { EVENTINSTANCES_GET_BY_EVENTID } from 'GraphQL/EventInstances/queries';
import { useQuery } from '@apollo/client';

import ArgonTypography from 'components/ArgonTypography';
import { useArgonController } from 'context';
import { Alert, Card, Collapse, Stack } from '@mui/material';
import selectData from 'options/selectData';

import NoImagePlaceholder from 'assets/images/placeholder/no_image.png';

import SettingsIcon from '@mui/icons-material/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import AddIcon from '@mui/icons-material/Add';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from 'Layout/DashboardLayout';
import DashboardNavbar from 'Layout/DashboardNavbar';
import { Link } from 'react-router-dom';
import { EVENT_GET_BY_ID } from 'GraphQL/Events/queries';
import { checkIfUserHasOrganizerAccess } from 'services/cognito/cognitoAccount';
import Loader from 'Loader';
import Footer from 'Layout/DashboardLayout/Footer';
import ArgonAlert from 'components/ArgonAlert';

function BaseLayout({
  navbarTitle,
  currentlySelectedEventInstanceId,
  eventId,
  // alloweduserGroups = 'event-admin',
  children
}) {
  // const navigate = useNavigate();

  const [hasAccess, setHasAccess] = useState('loading');
  const navigate = useNavigate();

  const [sortedEventInstances, setSortedEventInstances] = useState([]);

  useEffect(() => {
    // check if user has required access (cognito groups), if not forward to login page
    checkIfUserHasOrganizerAccess().then((result) => {
      if (!result) {
        navigate('/login', {
          state: { from: window.location.pathname },
          replace: true
        });
      }

      if (result) setHasAccess(true);
    });
  }, []);

  const {
    data: eventData,
    loading: eventDataLoading,
    error: eventDataError
  } = useQuery(EVENT_GET_BY_ID, {
    variables: { eventId: eventId },
    onCompleted: async (data) => {
      console.log(
        'Event Baselayout: Event data received from Database: ',
        data
      );
    },
    onError: (error) => {
      console.error(
        'Event Baselayout: Error getting event data from Database: ',
        error
      );
    },
    skip: !eventId
  });

  const { data, loading, error } = useQuery(EVENTINSTANCES_GET_BY_EVENTID, {
    variables: { eventId: eventId },
    onCompleted: async (data) => {
      console.log(
        'Event Instance Selection Wrapper: Event Instances Selection Wrapper received from Database: ',
        data
      );
      if (data?.eventInstances?.length > 0) {
        // sort eventIntances by date (ascending)
        const sortedEventInstances = data.eventInstances
          .slice()
          .sort((a, b) => {
            return new Date(a?.endDate) - new Date(b?.endDate);
          });
        // data.eventInstances.sort((a, b) => {
        //   return (
        //     new Date(a?.endDate || '2000-01-01') -
        //     new Date(b?.endDate || '2000-01-01')
        //   );
        // });
        console.log(
          'Event Instance Selection Wrapper: Sorted Event Instances: ',
          // data.eventInstances
          sortedEventInstances
        );

        setSortedEventInstances(sortedEventInstances);

        // filter out all eventInstances that are in the past
        const upcomingEventInstances = sortedEventInstances.filter(
          (eventInstance) => new Date(eventInstance.endDate) >= new Date()
        );

        console.log(
          'Event Instance Selection Wrapper: upcomingEventInstances: ',
          upcomingEventInstances
        );

        // set the first upcoming eventInstance as the currently selected eventInstance
        const firstUpcomingEventInstance = upcomingEventInstances[0]
          ? upcomingEventInstances[0]._id
          : null;

        console.log(
          'Event Instance Selection Wrapper: firstUpcomingEventInstance',
          firstUpcomingEventInstance,
          upcomingEventInstances[0]?.name
        );

        // handleEventInstanceSelected(firstUpcomingEventInstance);
        // navigate(`/organizer/events/${eventId}/${firstUpcomingEventInstance}`);
      }
    },
    onError: (error) => {
      console.error(
        'Event Instance Selection Wrapper Layout: Error getting event instances from Database: ',
        error
      );
    }
  });

  const handleEventInstanceSelected = (eventInstanceId) => {
    console.log('handleEventInstanceSelected', eventInstanceId);
    // navigate(`/organizer/events/${eventId}/${eventInstanceId}`);
  };

  // get event data from one of the eventInstances
  // const event = data?.eventInstances[0]?.event;
  const event = eventData?.event;
  const eventLogo = event?.pic_logo_url || NoImagePlaceholder;

  if (hasAccess === 'loading') return <Loader />;

  if (hasAccess === true)
    return (
      <>
        <DashboardLayout>
          <DashboardNavbar title={navbarTitle} />
          <Collapse in={Boolean(data)}>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12}>
                <ArgonBox mb={1}>
                  <Card
                    id="profile"
                    onClick={() => handleEventInstanceSelected(null)}>
                    <ArgonBox p={2}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <ArgonBox
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <ArgonBox
                              component="img"
                              src={eventLogo}
                              shadow="md"
                              borderRadius="md"
                              width="80px"
                              height="80px"
                              style={{
                                objectFit: 'cover'
                              }}
                            />
                          </ArgonBox>
                        </Grid>
                        <Grid item>
                          <ArgonBox height="100%" mt={0.5} lineHeight={1}>
                            <ArgonTypography variant="h5" fontWeight="medium">
                              {event?.name || 'New Event'}
                            </ArgonTypography>
                            <ArgonTypography
                              variant="button"
                              color="text"
                              fontWeight="medium">
                              {event?.descr_short}
                            </ArgonTypography>
                          </ArgonBox>
                        </Grid>
                      </Grid>
                    </ArgonBox>
                  </Card>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={6} md={3} xl={3}>
                <Link to={`/organizer/events/${eventId}`}>
                  <Card
                    sx={{
                      cursor: 'pointer',
                      boxShadow:
                        !currentlySelectedEventInstanceId &&
                        'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                      '&:hover': {
                        // opacity: 0.8,
                        transition: 'all 0.5s ease',
                        // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                        boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                        transform: 'translate(0px, -3px)'
                        // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px',
                        // filter: 'brightness(50%)',
                        // '*': 'inherit',
                      }
                    }}
                    onClick={() => handleEventInstanceSelected(null)}>
                    <ArgonBox p={1} pl={2} display="flex" alignItems="center">
                      <TuneIcon color="dark" />
                      &nbsp;
                      <ArgonTypography variant="button" noWrap>
                        {/* {event?.name || 'New Event'} */}
                        Event Setup
                      </ArgonTypography>
                    </ArgonBox>
                  </Card>
                </Link>
                {/* </ArgonBox> */}
              </Grid>

              {
                // data?.eventInstances
                sortedEventInstances?.map((eventInstance) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      xl={3}
                      key={eventInstance?._id}>
                      {/* <ArgonBox style={{ minWidth: '250px' }}> */}
                      <Link
                        to={`/organizer/events/${eventId}/${eventInstance._id}`}>
                        <Card
                          sx={{
                            cursor: 'pointer',
                            boxShadow:
                              currentlySelectedEventInstanceId ===
                                eventInstance?._id &&
                              'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                            '&:hover': {
                              // opacity: 0.8,
                              transition: 'all 0.5s ease',
                              // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                              boxShadow:
                                'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                              transform: 'translate(0px, -3px)'
                              // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px',
                              // filter: 'brightness(50%)',
                              // '*': 'inherit',
                            }
                          }}
                          onClick={() =>
                            handleEventInstanceSelected(eventInstance?._id)
                          }>
                          <ArgonBox
                            p={1}
                            pl={2}
                            display="flex"
                            alignItems="center">
                            <RocketLaunchIcon color="dark" />
                            &nbsp;
                            <ArgonTypography variant="button" noWrap>
                              {eventInstance?.name ||
                                selectData.eventInstanceTypes.find(
                                  (type) => type.value === eventInstance?.type
                                )?.label}
                            </ArgonTypography>
                          </ArgonBox>
                        </Card>
                      </Link>
                      {/* </ArgonBox> */}
                    </Grid>
                  );
                })
              }
              {/* <Grid item xs={12} sm={6} md={3} xl={2}>
                
                <Card
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: 'rgba(3, 102, 214, 0)',

                    boxShadow: 0,
                    //   selectedEventInstanceId === eventInstance?._id &&
                    //   'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                    '&:hover': {
                      // opacity: 0.8,

                      transition: 'all 0.5s ease',
                      // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                      boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                      transform: 'translate(0px, -3px)'
                      // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px',
                      // filter: 'brightness(50%)',
                      // '*': 'inherit',
                    }
                  }}
                  onClick={() => handleEventInstanceSelected()}>
                  <ArgonBox p={1} pl={2} display="flex" alignItems="center">
                    <AddIcon color="dark" />
                    &nbsp;
                    <ArgonTypography variant="button">
                      Add New Event Instance
                    </ArgonTypography>
                  </ArgonBox>
                </Card>
              </Grid> */}
            </Grid>
          </Collapse>
          <ArgonBox mt={2} mb={1}>
            <Collapse
              in={
                !loading &&
                !error &&
                !eventDataLoading &&
                !eventDataError &&
                !eventData?.event?.eventIsVisible == true
              }>
              <ArgonBox mt={1}>
                <Alert severity="warning">
                  <ArgonTypography variant="regular">
                    <ArgonBox>
                      This event has not been published yet and will not be
                      visible for athletes. Go to the{' '}
                      <u>
                        <Link
                          to={'/organizer/events/' + event?._id}
                          style={{
                            color: 'inherit'
                          }}>
                          event setup page
                        </Link>
                      </u>{' '}
                      to publish the event.
                    </ArgonBox>
                  </ArgonTypography>
                </Alert>
              </ArgonBox>
            </Collapse>
          </ArgonBox>
          {children}
          <ArgonBox mt={1} mb={2}>
            <Footer />
          </ArgonBox>
        </DashboardLayout>
      </>
    );
}

export default BaseLayout;
