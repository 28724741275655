/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Argon Dashboard 2 PRO MUI components
import ArgonTypography from 'components/ArgonTypography';

// Custom styles for ArgonProgress
import ArgonProgressRoot from 'components/ArgonProgress/ArgonProgressRoot';

const ArgonProgress = forwardRef(
  ({ variant, color, value, label, size, ...rest }, ref) => {
    let labelSize = 'caption';

    switch (size) {
      case 'small':
        labelSize = 'caption';
        break;
      case 'medium':
        labelSize = 'button';
        break;
      case 'large':
        labelSize = 'h6';
        break;
      default:
        labelSize = 'button';
        break;
    }

    return (
      <>
        {label && (
          <ArgonTypography variant={labelSize} fontWeight="medium" color="text">
            {value}%
          </ArgonTypography>
        )}
        <ArgonProgressRoot
          {...rest}
          size={size}
          ref={ref}
          variant="determinate"
          value={value}
          ownerState={{ color, value, variant }}
        />
      </>
    );
  }
);

// Setting default values for the props of ArgonProgress
ArgonProgress.defaultProps = {
  variant: 'contained',
  color: 'info',
  value: 0,
  label: false
};

// Typechecking props for the ArgonProgress
ArgonProgress.propTypes = {
  variant: PropTypes.oneOf(['contained', 'gradient']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark'
  ]),
  value: PropTypes.number,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default ArgonProgress;
