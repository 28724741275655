import React from 'react';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonDropzoneS3 from 'components/ArgonDropzoneS3';

function EventLogo({ eventData, onLogoUploaded }) {
  return (
    <ArgonBox display="flex" flexDirection="column" height="100%">
      <ArgonBox mb={0.5} mt={0} width="100%">
        <ArgonTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize">
          Event Logo
        </ArgonTypography>
        <ArgonTypography
          component="label"
          variant="caption"
          fontWeight="regular">
          &nbsp;(drop or click to change)
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox mb={0} mt={0} height="100%">
        <ArgonDropzoneS3
          id="upload_logo_dropzone"
          pathToPresignedUrl={`${process.env.REACT_APP_BACKEND_URI}/v1/media/get-signed-url`}
          key={eventData?.pic_logo_url || 'emptyDropzone'}
          onFileAdded={(url) => {
            onLogoUploaded(url);
          }}
          payloadPurpose="event-image"
          image={eventData?.pic_logo_url}
          metaData={{
            eventId: eventData?._id
          }}
          message=""
          identifier={eventData?._id}
          options={{
            paramName: 'file',
            method: 'PUT',
            chunking: true,
            chunkSize: 1000000,
            parallelChunkUploads: true,
            retryChunks: true,
            retryChunksLimit: 3,
            forceChunking: true,
            parallelUploads: 3,
            addRemoveLinks: true,
            acceptedFiles: 'image/*,.jpg,.jpeg,.png,.gif',
            autoQueue: true,
            autoProcessQueue: true
          }}
        />
      </ArgonBox>
    </ArgonBox>
  );
}

export default EventLogo;
