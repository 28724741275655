import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MESSAGETEMPLATES_QUERY } from 'GraphQL/MessageTemplates/queries';
import { useQuery } from '@apollo/client';
import ArgonSelect from 'components/ArgonSelect';

function MessageTemplateSelector({
  id,
  name,
  organizationId,
  formik,
  ...rest
}) {
  const [availableOptions, setAvailableOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});

  console.log('MessageTemplateSelector: organizationId:', organizationId);

  const {
    data,
    loading,
    error,
    refetch: refetchMessages
  } = useQuery(MESSAGETEMPLATES_QUERY, {
    variables: {
      query: { organization: organizationId }
    },
    onCompleted: async (result) => {
      console.log('MessageTemplateSelector: received from Database: ', result);

      const templateOptions = result.messagetemplates.map(
        (messageTemplate) => ({
          value: messageTemplate._id,
          label: messageTemplate.name
        })
      );

      const groupedOptions = [
        {
          label: 'NO TEMPLATE',
          options: [{ value: null, label: "Don't use a template" }]
        },
        {
          label: 'STANDARD TEMPLATES',
          options: [
            {
              value: process.env.REACT_APP_STANDARD_MESSAGE_TEMPLATE_ID,
              label: 'STRONG ONE'
            }
          ]
        },
        {
          label: 'EXISTING TEMPLATES',
          options: templateOptions
        }
      ];

      console.log(
        'MessageTemplateSelector: Available Message Templates: ',
        groupedOptions
      );
      setAvailableOptions(groupedOptions);
    },
    onError: (error) => {
      console.error(
        'MessageTemplateSelector: Error receiving MessageTemplates:',
        error
      );
    },
    skip: !organizationId,
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    setSelectedOption(
      availableOptions
        .map((group) => group.options)
        .flat()
        .find((option) => option.value === formik?.values?.[name])
    );
  }, [formik, availableOptions]);

  return (
    <>
      <ArgonSelect
        id={id}
        name={name}
        overflow
        options={availableOptions}
        value={selectedOption}
        onChange={(option) => {
          console.log('MessageTemplateSelector: option selected:', option);
          setSelectedOption(option);
          formik.setFieldValue(name, option.value);
        }}
        isLoading={loading}
        // value={value}
        // onChange={(option) => {
        //   console.log('Selected Option:', option);
        //   onChange && onChange(option);
        // }}
        {...rest}
      />
    </>
  );
}

MessageTemplateSelector.propTypes = {};

export default MessageTemplateSelector;
