/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// Settings page components

import BasicInfo from './components/BasicEventInfo';

import PublishOrDelete from './components/PublishOrDelete';

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';

import { EVENT_GET_BY_ID, EVENTS_GET_ALL } from 'GraphQL/Events/queries';

import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { getSession } from 'services/cognito/cognitoAccount';

import { EVENT_UPDATE } from 'GraphQL/Events/mutations';
import { AppBar, Collapse, Tab, Tabs } from '@mui/material';
import ObjectID from 'bson-objectid';
import { EVENTINSTANCES_GET_BY_EVENTID } from 'GraphQL/EventInstances/queries';
import { EVENTINSTANCE_INSERT } from 'GraphQL/EventInstances/mutations';
// import RegisteredAthletesList from './EventInstances/Participants/RegisteredAthletesList';
import Loader from 'Loader';
import { useSnackbar } from 'notistack';
import { createStripeProduct } from 'services/REST_API/stripe';
import selectData from 'options/selectData';
import LegalDocs from './components/LegalDocs';
import { deleteEvent } from 'services/restApi';
import CreateEventInstances from './components/BasicEventInfo/components/CreateEventInstances';

function EventSetup({ eventId, isNewRecord }) {
  // const { id } = useParams();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  const [selectedTab, setSelectedTab] = React.useState('setup');

  const [gqlUpdateEvent] = useMutation(EVENT_UPDATE);
  const [
    fetchAllEvents
    // { loading: eventsLoading, error: eventsError, data: eventsData },
  ] = useLazyQuery(EVENTS_GET_ALL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('Events re-fetched:', data);
    }
  });

  // console.log("rendering event-details-index");

  // console.log("ID param: ", id);

  const gqlEvent =
    // {
    // loading,
    // error,
    // data,
    // refetch: refetchEvent,
    // }
    useQuery(EVENT_GET_BY_ID, {
      fetchPolicy: 'network-only',
      variables: { eventId: eventId },
      onCompleted: async (data) => {
        console.log('Event Setup: Event data received from Database: ', data);
        await setFieldValues(data.event);
        if (!data?.event)
          Swal.fire('Error', 'Event not found', 'error').then(() =>
            navigate('/organizer/events')
          );
      }
    });

  const gqlEventInstances =
    // {
    // loading,
    // error,
    // data,
    // refetch: refetchEvent,
    // }
    useQuery(EVENTINSTANCES_GET_BY_EVENTID, {
      fetchPolicy: 'network-only',
      variables: { eventId: eventId },
      onCompleted: (data) => {
        console.log('Event Instances loaded from database:', data);
      },
      onError: (error) => {
        console.log('Error loading event instances', error);
      }
    });

  const refetchEvent = () => gqlEvent.refetch();

  const [gqlInsertOneEventInstance] = useMutation(EVENTINSTANCE_INSERT);

  const createEventInstance = async (values) => {
    console.log(
      'Creating event instance',
      values,
      gqlEvent?.data?.event?.organization?._id
    );

    let eventInstanceId = new ObjectID().toHexString();
    let newStripeProduct;

    if (
      gqlEvent?.data?.event?.organization?.stripeAccountId &&
      values.type !== 'leaderboard'
    ) {
      console.log('Creating Stripe product for Event Instance');
      newStripeProduct = await createStripeProduct({
        organizationId: gqlEvent?.data?.event?.organization?._id,
        productName:
          gqlEvent?.data?.event?.name +
          ' - ' +
          selectData.eventInstanceTypes.find(
            (eit) => eit.value === values?.type
          ).label,
        description: gqlEvent?.data?.event?._id,

        defaultCurrency:
          gqlEvent?.data?.event?.currency ||
          gqlEvent?.data?.event?.organization?.currency ||
          'EUR',
        defaultPrice: 0,
        stripeProductTaxCode: 'txcd_20030000',
        imageUrl: gqlEvent?.data?.event?.pic_logo_url,
        productType: 'eventInstance',
        productDbId: eventInstanceId
      }).catch((err) => {
        console.error('Error creating stripe product', err);
      });
    }
    console.log('New Stripe Product: ', newStripeProduct);

    await gqlInsertOneEventInstance({
      variables: {
        data: {
          _id: eventInstanceId,
          type: values?.type,
          event: { link: gqlEvent?.data?.event?._id },
          organizationId: gqlEvent?.data?.event?.organization?._id,
          currency: gqlEvent?.data?.event?.organization?.currency || 'EUR',
          creationDate: new Date(),
          isOpenRegistration: true,
          isVirtualEvent: values?.type === 'onlineQualifier' ? true : false,
          stripeProductId: newStripeProduct?.id,
          stripePriceId: newStripeProduct?.default_price
        }
      }
    })
      .then(async (result) => {
        console.log('result from update operation', result);
        // insert new stripe product for event instance
        // create a stripe product for the event instance
        // if (gqlEvent?.data?.event?.organization?.stripeAccountId) {
        //   console.log('Creating Stripe product for Event Instance');
        //   await createStripeProduct({
        //     organizationId: result?.insertOneEventinstance?.organization?._id,
        //     productName:
        //       gqlEvent?.data?.event?._id +
        //         ' - ' +
        //         result?.insertOneEventinstance?.name ||
        //       selectData.eventInstanceTypes.find(
        //         (eit) => eit.value === values?.type
        //       ).label,
        //     description: gqlEvent?.data?.event?._id,
        //     imageUrl: result?.insertOneEventinstance?.images[0]?.medium?.url,
        //     defaultCurrency: result?.insertOneEventinstance?.currency,
        //     defaultPrice: Number(result?.insertOneEventinstance?.price)
        //   }).catch((err) => {
        //     console.error('Error creating stripe product', err);
        //   });
        // } else {
        //   console.log(
        //     'No Stripe account found for organization, skipping creation of stripe product'
        //   );
        // }

        gqlEventInstances.refetch();
        Swal.fire({
          title: 'Success!',
          text: 'Your Event Instance has been created!',
          icon: 'success',
          timer: 3000
        });
        navigate(
          `/organizer/events/${result?.data?.insertOneEventInstance?.event?._id}/${result?.data?.insertOneEventInstance?._id}/setup`
        );
      })
      .catch((err) => {
        console.error('error', err);
      });
  };

  const setFieldValues = async (event) =>
    new Promise((resolve, reject) => {
      console.log('Setting field values', event);
      // console.log("Event data received from Database: ", data);
      Promise.allSettled([
        formik.setFieldValue('_id', event?._id),
        formik.setFieldValue('organization', event?.organization?._id),
        formik.setFieldValue('organizationId', event?.organization?._id),
        formik.setFieldValue('name', event?.name),
        formik.setFieldValue('type', event?.type),
        formik.setFieldValue('date', event?.date),
        formik.setFieldValue('descr_short', event?.descr_short),
        formik.setFieldValue('descr_long', event?.descr_long),
        formik.setFieldValue('pic_logo_url', event?.pic_logo_url),
        formik.setFieldValue('sports', event?.sports),
        formik.setFieldValue('hasOnlineQualifier', event?.hasOnlineQualifier),
        formik.setFieldValue(
          'onlineQualifierStartDate',
          event?.onlineQualifierStartDate
        ),
        formik.setFieldValue(
          'onlineQualifierEndDate',
          event?.onlineQualifierEndDate
        ),
        formik.setFieldValue('forIndividuals', event?.forIndividuals),
        formik.setFieldValue('forTeams', event?.forTeams),
        formik.setFieldValue('forUnderage', event?.forUnderage),
        formik.setFieldValue('ageGroupOptions', event?.ageGroupOptions),
        formik.setFieldValue('divisions', event?.divisions),
        formik.setFieldValue('currency', event?.currency),

        formik.setFieldValue('eventIsVisible', event?.eventIsVisible)
      ]).then(async () => {
        // hack to wait for all promises to resolve
        await new Promise((resolve) => setTimeout(resolve, 500));

        console.log('Event pic url: ', event?.pic_logo_url);
        console.log('Formik pic url: ', formik.values.pic_logo_url);
        resolve('done');
      });
      // formik.setFieldValue("forTeams", event?.participantLimit);
      // formik.setFieldValue("forTeams", event?.participationFee);
      // formik.setFieldValue("forTeams", event?.pic_logo_url);
    });

  // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    console.log('Firing useEffect in EventDetails');
    if (eventId === undefined) formik.resetForm();
    getSession()
      .then(async (session) => {
        console.log('Event Details: Session: ', session);
        // // if not logged in, redirect to login
        // const orgaId = session.idToken.payload['cognito:groups']
        //   .filter((g) => g.substring(0, 10) === 'orga-admin')[0]
        //   .split('-')[2];

        // console.log('Setting orga ID:', orgaId);

        // await formik.setFieldValue('organizationId', orgaId);
        // console.log('Formik OrgaId: ', formik.values.organizationId);
      })
      .catch((err) => {
        console.log('Error getting session: ', err);
        // const prevLocation = location();
        // console.log("Not logged in, redirecting to login page");
        // navigate(`/login?redirectTo=${prevLocation}`);
        // navigate(`/login`);
      });
    // delay(3000).then(async () => {
    //   // refresh cognito session again after 3 seconds to make sure all groups are loaded (if new event is created, it seems to need a few secs before cognito is updated)
    //   console.log('Refreshing session');
    //   const newSession = await refreshIdToken();
    //   console.log('New session: ', newSession);
    //   console.log(
    //     'Cognito Groups:',
    //     newSession.idToken.payload['cognito:groups']
    //   );
    //   // TODO: now refresh Realm Login
    //   apolloClient.link = new HttpLink({
    //     uri: `https://eu-central-1.aws.realm.mongodb.com/api/client/v2.0/app/${process.env.REACT_APP_REALM_APP_ID}/graphql`,
    //     // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
    //     // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
    //     // access token before sending the request.
    //     fetch: async (uri, options) => {
    //       const accessToken = await newSession.idToken.jwtToken;
    //       options.headers.Authorization = `Bearer ${accessToken}`;
    //       return fetch(uri, options);
    //     }
    //   });

    //   // TODO: now refresh all Events
    // });
  }, [eventId]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    sports: Yup.string().required('Required')
    // date: Yup.date().required('Required'),
    //descr_short: Yup.string().required('Required')
    // descr_long: Yup.string().required("Required"),
    // forIndividuals: Yup.boolean().required("Required"),
    // forTeams: Yup.boolean().required("Required"),
    // forKids: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: new ObjectID().toHexString(),
      organizationId: null,
      name: '',
      type: '',
      sports: '',
      date: '',
      descr_short: '',
      descr_long: '',
      hasOnlineQualifier: false,
      forIndividuals: false,
      forTeams: false,
      forUnderage: false,
      ageGroupOptions: [],
      divisions: [],
      currency: 'EUR',
      pic_logo_url: '',
      eventIsVisible: false

      // pic_logo_url: String,
      // hasOnlineQualifier: Boolean,
      // participantLimit: Number,
      // participationFee: Number,
      // onlineQualifierEndDate: Date,
      // onlineQualifierStartDate: Date,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));

      if (!eventId) {
        // if new event to be created
        // handleCreateEvent(values);
        // createEvent(values)
        //   .then((result) => {
        //     console.log("Result from createEvent: ", result);
        //     formik.setFieldValue("_id", result?.eventId);
        //     refetchEvent({ variables: { _id: result?.eventId } }).then(
        //       (result) => {
        //         // fetchAllEvents().then((result) => {
        //         Swal.fire({
        //           title: "Good job!",
        //           text: "Your event has been created!",
        //           icon: "success",
        //         });
        //       }
        //     );
        //   })
        //   .catch((err) => {
        //     console.error(err);
        //     Swal.fire({
        //       title: "Whoops!",
        //       text: "Something went wrong. Please try again.",
        //       icon: "error",
        //     });
        //   });
      } else {
        // if existing event to be updated
        console.log('Updating event... ');
        await handleUpdateEvent(values);
      }
    }
  });

  console.log('Formik errors: ', formik.errors);

  const handlePublishEventButtonClicked = async (eventIsVisible) => {
    if (eventIsVisible !== undefined)
      gqlUpdateEvent({
        variables: {
          eventId: eventId,
          data: {
            eventIsVisible: eventIsVisible
          }
        }
      })
        .then(async (result) => {
          console.log('Result from updateEvent: ', result);

          await setFieldValues(result.data.updateOneEvent);
          if (eventIsVisible) {
            Swal.fire({
              title: 'Event published successfully',
              text: 'Your event is now visible to the public.',
              icon: 'success',
              timer: 3000
            });
          } else {
            Swal.fire({
              title: 'Event unpublished successfully',
              text: 'Your event is not visible to the public anymore.',
              icon: 'warning',
              timer: 3000
            });
          }
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar('Something went wrong. Please try again.', {
            variant: 'error'
          });
        });
  };

  const handleUpdateEvent = async (eventData) => {
    console.log('Updating event... ');
    const temp = {
      ...eventData
    };
    // remove nested arrays as the contain a "__type" field which will fail the update
    delete temp.divisions;
    delete temp.subDivisionOptions;

    console.log('Values to be sent to update: ', temp);

    gqlUpdateEvent({
      variables: {
        eventId: eventId,
        data: temp
      }
    })
      .then(async (result) => {
        console.log('Result from updateEvent: ', result);

        await setFieldValues(result.data.updateOneEvent);

        enqueueSnackbar('Event updated successfully', { variant: 'success' });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar('Something went wrong. Please try again.', {
          variant: 'error'
        });
      });
  };

  const handleDeleteEvent = () => {
    // if no id available (no saved event loaded), abort function
    if (isNewRecord) {
      console.error('No event id availabl to delete.');
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error'
      });
      // Swal.fire({
      //   title: 'Whoops!',
      //   text: 'Something went wrong. Please try again.',
      //   icon: 'error'
      // });
      return null;
    }
    // else get confirmation
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEvent(formik.values._id)
          .then(async (result) => {
            await fetchAllEvents().then((result) => {
              console.log('Refetch result: ', result);
              enqueueSnackbar('Event deleted successfully', {
                variant: 'success'
              });
              navigate('/admin/events');

              // Swal.fire(
              //   'Deleted!',
              //   'Your event has been deleted.',
              //   'success'
              // ).then(() => {
              //   navigate('/admin/events');
              // });
            });
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar('Something went wrong. Please try again.', {
              variant: 'error'
            });
            // Swal.fire({
            //   title: 'Whoops!',
            //   text: 'Something went wrong. Please try again.',
            //   icon: 'error'
            // });
          });
      }
    });
  };

  // const handleFormikValuesChanged = (fieldName, value) => {
  //   console.log("TLC: Formik field changed:", fieldName, value, typeof value);

  //   formik.setFieldValue(fieldName, value);
  // };

  // const handleRemoveAgeGroupOption = (option) => {
  //   console.log("handleRemoveAgeGroupOption", option);
  // };

  // const handleAddSelectedAgeGroupOption = (option) => {
  //   console.log("handleAddSelectedAgeGroupOption", option);
  // };

  // const handleRemoveSelectedAgeGroupOption = (option) => {
  //   console.log("handleRemoveSelectedAgeGroupOption", option);
  // };

  const handleUpdateEventLogoUrl = (url) => {
    console.log('handleUpdateEventLogoUrl', url);
    formik.setFieldValue('pic_logo_url', url);
    console.log('formik.values.pic_logo_url', formik.values.pic_logo_url);
    if (!isNewRecord) {
      // if not a new record, then directly update record. if new record, just store the url in formik state (done above)
      handleUpdateEvent({ _id: formik.values._id, pic_logo_url: url });
    }
  };

  const handleSaveEvent = async (newData) => {
    console.log('handelSaveEvent', newData);

    // set all values to formik
    // await formik.setValues({ ...formik.values, ...data });
    // await formik.setValues(data);
    await setFieldValues(newData);
    await formik.submitForm();
  };

  // const Loader = () => {
  //   return (
  //     <Box
  //       display='flex'
  //       justifyContent='center'
  //       alignItems='center'
  //       minHeight='100vh'
  //     >
  //       <PuffLoader color='#36d7b7' />
  //     </Box>
  //   );
  // };

  if (gqlEvent.loading || gqlEventInstances.loading) {
    return <Loader />;
  }

  // if (error) {
  //   console.log("Error: ", error);
  //   return <p>No data found :(</p>;
  // }

  // console.log("Logo URL: ", formik.values.pic_logo_url);

  // const handleTabChange = (newValue) => {
  //   console.log('handleTabChange', newValue);
  //   setSelectedTab(newValue);
  // };

  if (gqlEvent.data || eventId === undefined) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md>
            <ArgonBox>
              {/* <Collapse in={!isNewRecord}>
                <ArgonBox mb={3}>
                  <Header
                    key={
                      gqlEvent.data?.event?.pic_logo_url +
                      gqlEvent.data?.event?.name +
                      gqlEvent.data?.event?.descr_short
                    }
                    visible={true}
                    title={gqlEvent.data?.event?.name}
                    subTitle={gqlEvent.data?.event?.descr_short}
                    logoUrl={gqlEvent.data?.event?.pic_logo_url}
                  />
                </ArgonBox>
              </Collapse> */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo
                    formik={formik}
                    onDeleteEvent={handleDeleteEvent}
                    onSaveEvent={handleSaveEvent}
                    onUpdateEventLogoUrl={handleUpdateEventLogoUrl}
                    // onCreateNewEventInstance={async (type) =>
                    //   await createEventInstance(type)
                    // }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CreateEventInstances
                    onCreateNewEventInstance={async (type) =>
                      await createEventInstance(type)
                    }
                  />
                </Grid>
              </Grid>
              <ArgonBox mt={3} />
              <Collapse in={formik.values._id !== ''}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <LegalDocs event={gqlEvent?.data?.event} />
                  </Grid>
                  {/*   <Grid item xs={12}>
                      <Social />
                    </Grid>
                    <Grid item xs={12}>
                      <Team />
                    </Grid>
                    <Grid item xs={12}>
                      <Publish />
                    </Grid>
                    <Grid item xs={12}>
                      <Authentication />
                    </Grid>
                    <Grid item xs={12}>
                      <Accounts />
                    </Grid>
                    <Grid item xs={12}>
                      <Notifications />
                    </Grid>
                    <Grid item xs={12}>
                      <Sessions />
                    </Grid> */}
                  <Grid item xs={12}>
                    <PublishOrDelete
                      key={formik.values?.eventIsVisible}
                      isPublished={formik.values?.eventIsVisible}
                      onPublish={handlePublishEventButtonClicked}
                      onUnpublish={handlePublishEventButtonClicked}
                      onDelete={handleDeleteEvent}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </ArgonBox>
          </Grid>
          {/* <Grid item xs={12} md={3} lg={2}>
            <Sidenav />
          </Grid> */}
        </Grid>

        {/* </ArgonBox>
        </BaseLayout> */}
      </>
    );
  }
}

export default EventSetup;
