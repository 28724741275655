import React, { useEffect, useState } from 'react';

import { getEventInstanceAutoInvitationStatistics } from 'services/REST_API/statistics';
import LoaderWrap from 'LoaderWrap';
import ArgonBox from 'components/ArgonBox';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import ArgonTypography from 'components/ArgonTypography';
import ArgonProgress from 'components/ArgonProgress';

export function AutoInvitationStatisticsCard({
  eventInstanceId,
  invitationsPerDivision
}) {
  return (
    <Card style={{ height: '100%' }}>
      <ArgonBox p={3}>
        <ArgonTypography variant="h6">Auto Invitations</ArgonTypography>
      </ArgonBox>
      <ArgonBox pb={3} px={3}>
        <AutoInvitationStatistics
          eventInstanceId={eventInstanceId}
          invitationsPerDivision={invitationsPerDivision}
        />
      </ArgonBox>
    </Card>
  );
}

function AutoInvitationStatistics({ eventInstanceId, invitationsPerDivision }) {
  const [statistics, setStatistics] = useState(null);
  const [divisionData, setDivisionData] = useState(null);

  const [loading, setLoading] = useState(true);

  console.log(
    'AutoInvitationStatistics: invitationsPerDivision:',
    invitationsPerDivision
  );

  useEffect(() => {
    if (eventInstanceId) {
      setLoading(true);
      getEventInstanceAutoInvitationStatistics({ eventInstanceId })
        .then((data) => {
          console.log('AutoInvitationStatistics: data from API:', data);
          setStatistics(data);

          // Extract array of objects with divisionIds and names
          const divisionData = data.reduce((acc, item) => {
            if (!acc.some((d) => d.divisionId === item.divisionTo)) {
              if (item.divisionTo)
                acc.push({
                  divisionId: item.divisionTo,
                  divisionName: item.divisionName
                });
            }
            return acc;
          }, []);

          console.log('Extracted division data:', divisionData);

          setDivisionData(divisionData);
        })
        .catch((error) => {
          console.error('Error fetching auto-invitation statistics:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [eventInstanceId]);

  if (!loading && !divisionData) {
    return (
      <div>
        <ArgonTypography variant="caption" color="dark">
          No data found.
        </ArgonTypography>
      </div>
    );
  }

  const totalInvitations =
    invitationsPerDivision?.reduce(
      (acc, division) => acc + division.inviteCount,
      0
    ) || '0';

  const totalInvitationsSent =
    invitationsPerDivision?.reduce(
      (acc, division) => acc + division.invitedRegistrations.length,
      0
    ) || '0';

  const totalAcceptedInvitations =
    statistics
      ?.filter((stats) => stats.status === 'accepted')
      ?.reduce((acc, stat) => acc + stat.count, 0) || '0';

  const totalDeclinedOrExpiredInvitations =
    statistics
      ?.filter(
        (stats) => stats.status === 'declined' || stats.status === 'expired'
      )
      ?.reduce((acc, stat) => acc + stat.count, 0) || '0';

  const totalPendingInvitations =
    statistics
      ?.filter((stats) => stats.status === 'pending')
      ?.reduce((acc, stat) => acc + stat.count, 0) || '0';

  return (
    <div>
      <LoaderWrap loading={loading}>
        <ArgonBox>
          <Table>
            {/* <TableHead> */}
            <TableBody>
              <TableRow>
                <TableCell style={{ minWidth: '20%', paddingLeft: '0px' }}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    Division
                  </ArgonTypography>
                </TableCell>

                <TableCell style={{ width: '20%' }}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    Progress
                  </ArgonTypography>
                </TableCell>
                <TableCell align="center" style={{ width: '15%' }}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    Planned Invitations
                  </ArgonTypography>
                </TableCell>

                <TableCell align="center">
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    Sent
                  </ArgonTypography>
                </TableCell>
                <TableCell align="center">
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    Accepted
                  </ArgonTypography>
                </TableCell>
                <TableCell align="center">
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    Pending
                  </ArgonTypography>
                </TableCell>
                <TableCell align="center">
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    Declined / Expired
                  </ArgonTypography>
                </TableCell>
              </TableRow>
              {/* </TableHead>
            <TableBody> */}
              {divisionData?.map((division) => {
                const divisionStatistics = statistics.filter(
                  (stat) => stat.divisionTo === division.divisionId
                );
                console.log(
                  'AutoInvitationStatistics: DivisionStatistics',
                  divisionStatistics
                );

                const invitationPerdivisionData = invitationsPerDivision?.find(
                  (invite) => invite.divisionToId === division.divisionId
                );

                const plannedInvitationCount =
                  invitationPerdivisionData?.inviteCount || 0;

                const sentInvitations =
                  invitationPerdivisionData?.invitedRegistrations?.length || 0;

                const acceptedInvitations = divisionStatistics
                  .filter((stat) => stat.status === 'accepted')
                  ?.reduce((acc, stat) => acc + stat.count, 0);

                const pendingInvitations = divisionStatistics
                  .filter((stat) => stat.status === 'pending')
                  ?.reduce((acc, stat) => acc + stat.count, 0);

                const declinedOrExpiredInvitations = divisionStatistics
                  .filter(
                    (stat) =>
                      stat.status === 'declined' || stat.status === 'expired'
                  )
                  ?.reduce((acc, stat) => acc + stat.count, 0);

                console.log(
                  'AutoInvitationStatistics: acceptedInvitations:',
                  acceptedInvitations
                );
                console.log(
                  'AutoInvitationStatistics: declinedOrExpiredInvitations:',
                  declinedOrExpiredInvitations
                );

                const acceptedPercentage =
                  (acceptedInvitations / plannedInvitationCount) * 100;

                console.log(
                  'AutoInvitationStatistics: acceptedPercentage:',
                  acceptedPercentage
                );

                const declinedOrExpiredPercentage =
                  (declinedOrExpiredInvitations / plannedInvitationCount) * 100;

                console.log(
                  'AutoInvitationStatistics: declinedOrExpiredPercentage:',
                  declinedOrExpiredPercentage
                );

                const pendingPercentage =
                  (pendingInvitations / plannedInvitationCount) * 100;

                return (
                  <TableRow key={division?.divisionId}>
                    <TableCell style={{ paddingLeft: '0px' }}>
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text">
                        {division?.divisionName}
                      </ArgonTypography>
                    </TableCell>

                    {/* progress          */}
                    <TableCell>
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text">
                        <ArgonProgress
                          size={'small'}
                          value={Number(acceptedPercentage)}
                          label={acceptedPercentage.toString()}
                        />
                      </ArgonTypography>
                    </TableCell>

                    <TableCell align="center">
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text">
                        {plannedInvitationCount || '0'}
                      </ArgonTypography>
                    </TableCell>

                    <TableCell align="center">
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text">
                        {sentInvitations || '0'}
                      </ArgonTypography>
                    </TableCell>
                    <TableCell align="center">
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text">
                        {`${
                          acceptedInvitations || '0'
                        } (${acceptedPercentage}%)`}
                      </ArgonTypography>
                    </TableCell>
                    <TableCell align="center">
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text">
                        {`${pendingInvitations || '0'} (${pendingPercentage}%)`}
                      </ArgonTypography>
                    </TableCell>
                    <TableCell align="center">
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text">
                        {`${
                          declinedOrExpiredInvitations || '0'
                        } (${declinedOrExpiredPercentage}%)`}
                      </ArgonTypography>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    Total
                  </ArgonTypography>
                </TableCell>
                <TableCell style={{ border: 'none' }}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark"></ArgonTypography>
                </TableCell>
                <TableCell align="center" style={{ border: 'none' }}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    {totalInvitations}
                  </ArgonTypography>
                </TableCell>
                <TableCell align="center" style={{ border: 'none' }}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    {totalInvitationsSent}
                  </ArgonTypography>
                </TableCell>
                <TableCell align="center" style={{ border: 'none' }}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    {totalAcceptedInvitations}
                  </ArgonTypography>
                </TableCell>
                <TableCell align="center" style={{ border: 'none' }}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    {totalPendingInvitations}
                  </ArgonTypography>
                </TableCell>
                <TableCell align="center" style={{ border: 'none' }}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="dark">
                    {totalDeclinedOrExpiredInvitations}
                  </ArgonTypography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ArgonBox>
      </LoaderWrap>
    </div>
  );
}

export default AutoInvitationStatistics;
