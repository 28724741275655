export function removeGqlGarbageFromArrays(array) {
  if (!array || !Array.isArray(array)) return [];

  const arrayCopy = [...array];
  return arrayCopy.map((item) => {
    const itemCopy = { ...item };
    delete itemCopy?.['__typename'];
    delete itemCopy?.['__schema'];
    delete itemCopy?.['__type'];
    return itemCopy;
  });
}
