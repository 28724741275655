import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    firstName
    lastName
    email
    dateOfBirth
    gender
    address1
    address2
    city
    zip
    country
    phone
    pic_url
    fcWidgetId
  }
`;
