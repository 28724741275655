import React from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { withStyles } from '@mui/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import ObjectID from 'bson-objectid';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledAutocomplete = withStyles({
  //   tag: {
  //     color: "#ad4e64",
  //     border: "1px solid #ad4e64",
  //     background: "transparent",
  //     svg: {
  //       color: "#ad4e64 !important",
  //     },
  //   },
})(Autocomplete);
const ERROR_TYPE = {
  DUPLICATE: 'duplicate'
};

const OPTIONS_TYPE = {
  NEW: 'new'
};

const filter = createFilterOptions();

export default function AutoCompleteTagsCreate({
  availableOptions,
  onAvailableOptionsChanged,
  onSelectedOptionsChanged
  //   onAddAvailableOption,
  //   onRemoveAvailableOption,
  //   selectedOptions,
  //   onAddSelectedOption,
  //   onRemoveSelectedOption,
}) {
  const tagOptions = [
    { _id: 1, name: 'HTML' },
    { _id: 2, name: 'CSS' },
    { _id: 3, name: 'JavaScript' },
    { _id: 4, name: 'AWS' }
  ];

  console.log('availableOptions from HOC', availableOptions);

  const [availableTagOptions, setAvailableTagOptions] = React.useState(
    availableOptions || []
  );

  const [selectedTags, setSelectedTags] = React.useState([
    // tagOptions[0],
    // tagOptions[1],
  ]);
  //   const [defaultTags, setDefaultTags] = React.useState([
  //       { _id: ObjectID().toString(), name: "Kids" },
  //       { _id: ObjectID().toString(), name: "Teens" },
  //       { _id: ObjectID().toString(), name: "Teens" },
  //       {_id: ObjectID().toString(), name: "Teens"},
  //   ]);
  const [tagName, setTagName] = React.useState('hello');
  const [selectedTag, setSelectedTag] = React.useState(null);
  const [selectedAddTag, setSelectedAddTag] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleAddNewTag = (newTag) => {
    console.log('handleAddNewTag: ', newTag);

    const tempAvailableTagOptions = [
      ...availableTagOptions,
      // { _id: maxId + 1, name: selectedAddTag.value },
      newTag
    ];

    const tempSelectedTagOptions = [
      ...selectedTags,
      // { _id: maxId + 1, name: selectedAddTag.value },
      newTag
    ];

    setSelectedTags(tempSelectedTagOptions);
    setAvailableTagOptions(tempAvailableTagOptions);

    console.log('Adding new Tag Option', newTag);
    // setAvailableTagOptions((prev) => [...prev, newTag]);
    // // add to available options on higher order component
    // if (onAddAvailableOption) onAddAvailableOption(newTag);
    // // add to selected tags on higher order component
    //   if (onAddSelectedOption) onAddSelectedOption(newTag);

    if (onAvailableOptionsChanged)
      onAvailableOptionsChanged(tempAvailableTagOptions);
    if (onSelectedOptionsChanged)
      onSelectedOptionsChanged(tempSelectedTagOptions);
    setOpenDialog(false);
  };

  React.useEffect(() => {
    if (selectedTag === null) {
      setTagName('');
    }
  }, [selectedTag, selectedAddTag]);

  return (
    <div>
      {/* <Grid container spacing={2}>
        <Grid item> */}
      <StyledAutocomplete
        // key={tagOptions}
        id="combo-box-demo"
        // size="small"
        disableCloseOnSelect
        options={availableTagOptions}
        // options={availableOptions}
        // defaultValue={defaultTags}
        value={selectedTags}
        multiple
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }

          return option.label;
        }}
        // style={{ width: 300 }}
        inputValue={tagName}
        // renderInput={(params) => (
        //   <TextField {...params} label="selectedTags" variant="outlined" />
        // )}
        renderInput={(params) => (
          <TextField
            {...params}
            // label="Age Groups"
            placeholder="Please select age groups or add new ones by typing and pressing enter"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selectedTags.some(
                (tagItem) => tagItem._id === option._id
              )}
            />
            {`${option.label}`}
          </li>
        )}
        onInputChange={(event, value) => {
          setTagName(value);
        }}
        onClose={(event, reason) => {
          if (reason === 'blur') {
            setSelectedTag(null);
          }
        }}
        onChange={(event, value, reason) => {
          switch (reason) {
            case 'clear':
              setSelectedTags([]);
              break;
            case 'removeOption':
              setSelectedTags(value);
              break;
            case 'selectOption':
              {
                const lastItem = value[value.length - 1];
                console.log('LastItem', lastItem);
                if (typeof lastItem === 'string') {
                  console.log('Is string, so do nothing');
                  setSelectedTag(null);
                  break;
                }
                if (!lastItem.type) {
                  console.log('New item, needs to be added to the list');
                  setSelectedAddTag(lastItem);
                  setOpenDialog(true);
                  break;
                }
                const isExists = selectedTags.some(
                  (tagItem) => tagItem._id === lastItem._id
                );
                console.log('isExists', isExists);
                if (isExists) {
                  setSelectedTag(null);
                  setSelectedTags(value);
                  console.log('Selected tag', value);
                } else {
                  setSelectedTag(lastItem);
                  setSelectedTags(value);
                  console.log('Selected tag', value);
                }
              }
              break;
            default:
              break;
          }
          console.log('nkc', value);
          console.log('nkc', reason);
        }}
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              type: OPTIONS_TYPE.NEW,
              value: params.inputValue,
              name: `Add "${params.inputValue}"`
            });
          }
          return filtered;
        }}
      />
      {/* </Grid>
      </Grid> */}
      <Dialog
        open={openDialog}
        onClose={() => {
          setSelectedAddTag(null);
          setOpenDialog(false);
        }}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          Do you want to add this age group option "
          {selectedAddTag?.value ?? ''}"?
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const newTag = {
                _id: ObjectID().toHexString() + 1,
                name: selectedAddTag.value
              };

              //   console.log("Adding new Tag Option", newTag);
              //   setAvailableTagOptions((prev) => [...prev, newTag]);
              handleAddNewTag(newTag);
            }}>
            Add
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setSelectedAddTag(null);
              setOpenDialog(false);
            }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
